<template>
  <div class="overview">
    <div v-if="statData.bloodPresure">
      <span class="title">血壓</span>
      <overview-item
        :icon="'bloodPresure'"
        :recordData="statData.bloodPresure"
        :message="recordMessage.bloodPresure"
      />
    </div>
    <div v-if="statData.bloodGlucose">
      <span class="title">血糖</span>
      <overview-item
        :icon="'bloodGlucose'"
        :recordData="statData.bloodGlucose"
        :message="recordMessage.bloodGlucose"
      />
    </div>
    <div v-if="statData.oxygenSat">
      <span class="title">血氧</span>
      <overview-item
        :icon="'sPO2'"
        :recordData="statData.oxygenSat"
        :message="recordMessage.oxygenSat"
      />
    </div>
    <div v-if="statData.bodyTemp">
      <span class="title">體溫</span>
      <overview-item
        :icon="'temperature'"
        :recordData="statData.bodyTemp"
        :message="recordMessage.bodyTemp"
      />
    </div>
    <div v-if="statData.bodyWeight">
      <span class="title">身體組成</span>
      <overview-item
        :icon="'weight'"
        :recordData="statData.bodyWeight"
        :message="recordMessage.bodyWeight"
      />
    </div>
  </div>
</template>

<script>
  import { toRef, reactive, watchEffect } from 'vue'
  import { measurementAvg } from '@/utils/index'
  import OverviewItem from './OverviewItem'
  export default {
    components: {
      OverviewItem,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const measurements = toRef(props, 'data')
      const statData = reactive({}) // 量測統計資料
      const recordMessage = reactive({}) // 量測統計資料訊息

      watchEffect(() => {
        const {
          bodyTempMeasurements: bodyTemp,
          glucoseMeasurements: bloodGlucose,
          bpMeasurements: bloodPresure,
          oxygenSatMeasurements: oxygenSat,
          bodyWeightFatMeasurements: bodyWeight,
        } = measurements.value

        // 血壓
        if (bloodPresure.length > 0) {
          statData.bloodPresure = [
            {
              title: '量測次數',
              value: bloodPresure.length,
              unit: '',
            },
            {
              title: '收縮壓',
              value: measurementAvg(bloodPresure, 'systolic'),
              unit: '毫米汞柱',
            },
            {
              title: '舒張壓',
              value: measurementAvg(bloodPresure, 'diastolic'),
              unit: '毫米汞柱',
            },
            {
              title: '脈壓差',
              value: measurementAvg(bloodPresure, 'pulsePressureDifference'),
              unit: '毫米汞柱',
            },
            {
              title: '脈搏',
              value: measurementAvg(bloodPresure, 'pulse'),
              unit: '次/分',
            },
            {
              title: '心律不整(ARR)',
              value: [
                bloodPresure.reduce(
                  (acc, item) => acc + (item.measurement.arr ? 1 : 0),
                  0
                ),
                bloodPresure.length,
              ],
            },
            {
              title: '早期收縮(PC)',
              value: [
                bloodPresure.reduce(
                  (acc, item) => acc + (item.measurement.pc ? 1 : 0),
                  0
                ),
                bloodPresure.length,
              ],
            },
            {
              title: '心房顫動(Afib)',
              value: [
                bloodPresure.reduce(
                  (acc, item) => acc + (item.measurement.afib ? 1 : 0),
                  0
                ),
                bloodPresure.length,
              ],
            },
          ]
        }
        // recordMessage.bloodPresure = '第一期高血壓'
        recordMessage.bloodPresure = ''

        // 血糖
        if (bloodGlucose.length > 0) {
          statData.bloodGlucose = [
            {
              title: '量測次數',
              value: bloodGlucose.length,
              unit: '',
            },
            {
              title: '空腹',
              value: measurementAvg(
                bloodGlucose.filter(
                  (item) => item.measurement.meal === 'Fasting'
                ),
                'bloodGlucose'
              ),
              unit: '毫克/分升',
            },
            {
              title: '餐前',
              value: measurementAvg(
                bloodGlucose.filter(
                  (item) =>
                    item.measurement.meal === 'Preprandial' ||
                    item.measurement.meal === 'Before Meal'
                ),
                'bloodGlucose'
              ),
              unit: '毫克/分升',
            },
            {
              title: '餐後',
              value: measurementAvg(
                bloodGlucose.filter(
                  (item) =>
                    item.measurement.meal === 'Postprandial' ||
                    item.measurement.meal === 'After Meal'
                ),
                'bloodGlucose'
              ),
              unit: '毫克/分升',
            },
          ]
        }
        recordMessage.bloodGlucose = ''

        // 血氧
        if (oxygenSat.length > 0) {
          statData.oxygenSat = [
            {
              title: '量測次數',
              value: oxygenSat.length,
              unit: '',
            },
            {
              title: '血氧值',
              value: Math.round(
                measurementAvg(oxygenSat, 'spo2Highest') * 0.5 +
                  measurementAvg(oxygenSat, 'spo2Lowest') * 0.5,
                0
              ),
              unit: '％',
            },
          ]
        }
        recordMessage.oxygenSat = ''

        // 體溫
        if (bodyTemp.length > 0) {
          statData.bodyTemp = [
            {
              title: '量測次數',
              value: bodyTemp.length,
              unit: '',
            },
            {
              title: '溫度',
              value: measurementAvg(bodyTemp, 'bodyTemperature', 1),
              unit: '℃',
            },
          ]
        }
        recordMessage.bodyTemp = ''

        // 身體組成
        if (bodyWeight.length > 0) {
          statData.bodyWeight = [
            {
              title: '量測次數',
              value: bodyWeight.length,
              unit: '',
            },
            {
              title: '體重',
              value: measurementAvg(bodyWeight, 'weight', 1),
              unit: '公斤',
            },
            {
              title: 'BMI',
              value: measurementAvg(bodyWeight, 'bmi', 1),
            },
            {
              title: '體脂率',
              value: measurementAvg(bodyWeight, 'bodyFat', 1),
              unit: '％',
            },
            {
              title: '肌肉率',
              value: measurementAvg(bodyWeight, 'muscleMass', 1),
              unit: '％',
            },
            {
              title: '內臟脂肪率',
              value: measurementAvg(bodyWeight, 'visceralFat', 1),
              unit: '等級',
            },
            {
              title: '基礎代謝率',
              value: measurementAvg(bodyWeight, 'bmr', 1),
              unit: '大卡',
            },
          ]
        }
        recordMessage.bodyWeight = ''
      })
      return { props, statData, recordMessage }
    },
  }
</script>

<style lang="less">
  .overview {
    background-color: #f5f5f5;
    padding: 20px;
    box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
    border-radius: 10px;
    .title {
      font-size: 26px;
      line-height: 32px;
    }
  }
</style>
