<template>
  <a-row :gutter="48">
    <a-col :span="24">
      <CaseBody :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
      <BodyAvgTable :records="measurements" />
    </a-col>
  </a-row>
  <BodyRecordDataTable :records="measurements" />
</template>

<script>
  import { toRef } from 'vue'
  import CaseBody from '@/components/charts/CaseBody'
  import BodyAvgTable from '@/views/healthManagement/caseManagement/caseDetail/BodyAvgTable'
  import BodyRecordDataTable from '@/views/healthManagement/caseManagement/caseDetail/BodyRecordDataTable'
  export default {
    components: {
      CaseBody,
      BodyAvgTable,
      BodyRecordDataTable,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const measurements = toRef(props, 'data')
      return {
        measurements,
      }
    },
  }
</script>

<style lang="less" scoped>
  .ant-col {
    height: 400px;
  }
</style>
