<template>
  <a-form
    v-if="userData"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 14 }"
    :labelAlign="'left'"
  >
    <a-form-item label="姓名">
      <span>{{ userData.name }}</span>
    </a-form-item>
    <a-form-item label="出生年月日">
      <span>{{ userData.birthday }}</span>
    </a-form-item>
    <a-form-item label="身分證字號">
      <span>{{ userData.IDNum }}</span>
    </a-form-item>
    <a-form-item label="性別">
      <span v-if="userData.gender === 'M'">男</span>
      <span v-else-if="userData.gender === 'F'">女</span>
      <span v-else>未填寫</span>
    </a-form-item>
    <a-form-item label="聯絡電話">
      <span>{{ userData.telNumber }}</span>
    </a-form-item>
    <a-form-item label="行動電話">
      <span>{{ userData.phoneNumber }}</span>
    </a-form-item>
    <a-form-item label="E-MAIL">
      <span>{{ userData.email }}</span>
    </a-form-item>
    <a-form-item label="通訊地址">
      <span>{{ userData.addr }}</span>
    </a-form-item>
    <a-form-item label="個案編號">
      <span>{{ userData.cardId }}</span>
    </a-form-item>

    <a-form-item v-if="false" label="檢視者">
      <div v-for="(item, index) in userData.viewers" :key="item.memberId">
        <span style="display: inline-block; width: 100px">
          {{ item.name }}
        </span>

        <a-button type="primary" ghost @click="showViewerEditModal(index)">
          變更
        </a-button>
        <a-popconfirm
          title="確定要刪除此檢視者？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteViewer(index)"
        >
          <a-button type="danger" ghost style="margin-left: 8px">刪除</a-button>
        </a-popconfirm>
      </div>
      <PlusCircleOutlined @click="addViewer" />
    </a-form-item>
  </a-form>
  <a-button
    type="primary"
    style="margin-left: 8px"
    @click="save"
    :disabled="!dataEdited"
  >
    儲存
  </a-button>

  <a-modal
    v-model:visible="viewerEdit"
    title="變更檢視者"
    width="80%"
    :footer="null"
  >
    <CustSearch :filter="filter" @search="handleViewerEditSearch" />
    <CustDataTable
      v-model:searchResult="searchResult"
      @action1="handelChooseVeiwer"
    />
  </a-modal>
</template>

<script>
  import { PlusCircleOutlined } from '@ant-design/icons-vue'
  import { toRef, ref, computed } from 'vue'
  import { useStore } from 'vuex'
  import { getCustInfo } from '@/api/cust'
  import CustSearch from '@/components/CustSearch'
  import CustDataTable from '@/components/CustDataTable'
  export default {
    components: {
      PlusCircleOutlined,
      CustSearch,
      CustDataTable,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const store = useStore()
      const userData = toRef(props.data, 'user')
      const caseData = toRef(props.data, 'case')
      const dataEdited = ref(false) // 是否有編輯資料
      const viewerEdit = ref(false)
      const viewerEditIndex = ref(-1) // 檢視者編輯idx
      const caseStoreList = ref([])
      const filter = computed(() => store.getters['cust/filter'])

      // 門市清單
      const handleStoreList = () => {
        const storeList = store.getters['store/storeList']

        return storeList.sort((a, b) => {
          return a.store_name > b.store_name ? 1 : -1
        })
      }
      caseStoreList.value = handleStoreList()

      const addViewer = () => {
        viewerEdit.value = true
        viewerEditIndex.value = -1
      }

      const save = () => {
        console.log(props.data.value)
      }

      const handelChooseVeiwer = async (memberID) => {
        const searchData = await getCustInfo({
          searchItem: 'memberID',
          searchValue: memberID,
        })
        if (viewerEditIndex.value > -1) {
          userData.value.viewers.splice(viewerEditIndex.value, 1, {
            memberId: searchData.memberId,
            name: searchData.name,
          })
        } else {
          userData.value.viewers.push({
            memberId: searchData.memberId,
            name: searchData.name,
          })
        }
        viewerEdit.value = false
      }

      const deleteViewer = (index) => {
        userData.value.viewers.splice(index, 1)
      }

      const showViewerEditModal = (index) => {
        viewerEdit.value = true
        viewerEditIndex.value = index
      }

      const searchResult = computed(() => {
        const data = store.getters['cust/searchResult']
        if (typeof data === 'undefined' || data.length === 0) {
          return []
        }
        data.map((item) => {
          item.action1 = '選擇'
        })
        return data
      })

      const handleViewerEditSearch = () => {
        store.dispatch('cust/search')
      }

      return {
        viewerEdit,
        addViewer,
        save,
        props,
        userData,
        caseData,
        handelChooseVeiwer,
        deleteViewer,
        showViewerEditModal,
        caseStoreList,
        filter,
        dataEdited,
        searchResult,
        handleViewerEditSearch,
      }
    },
  }
</script>

<style></style>
