<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import moment from 'moment'
  import { getChartYAxisRange } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      let temp = []
      const option = ref(null)
      const echartInit = () => {
        // // 排序資料
        dataCopy.value.sort(
          (a, b) =>
            new Date(a.measurement.measured) - new Date(b.measurement.measured)
        )

        // 取得體溫Y軸刻度範圍
        const tempDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bodyTemperature)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bodyTemperature)
          ),
        }
        const tempChartRange = getChartYAxisRange(tempDataRange)

        // 體溫
        temp = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.bodyTemperature,
        ])

        const data = [
          {
            name: '體溫',
            type: 'line',
            data: temp,
          },
        ]
        option.value = {
          title: {
            text: '體溫紀錄',
            textStyle: {
              fontSize: '20',
            },
            left: '30',
          },
          color: ['#04DACD'],
          grid: {
            left: '60',
            right: '5%',
            bottom: '70',
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              let tar = `量測日期 ${moment(params[0].value[0]).format(
                'YYYY-MM-DD'
              )}<br/>`
              tar += `<span class="chart-tooltip-point" style="background-color:${params[0].color};"></span>
              ${params[0].seriesName}:  ${params[0].value[1]} 毫米汞柱<br/>`
              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['體溫'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'time',
              axisPointer: {
                type: 'shadow',
              },
              minInterval: 3600 * 24 * 1000,
              maxInterval: 3600 * 24 * 1000,
              axisLabel: {
                formatter: (value) =>
                  moment(value).format('dd') +
                  '\n' +
                  moment(value).format('M/D'),
              },
              minorTick: {
                show: true,
                splitNumber: 2,
                length: 3,
              },
              splitLine: {
                lineStyle: {
                  color: '#99E1D6',
                  width: 2,
                },
              },
              minorSplitLine: {
                show: true,
                lineStyle: {
                  color: '#BFECE5',
                  type: 'dashed',
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '度',
              min: tempChartRange.min,
              max: tempChartRange.max,
              interval: tempChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100,
              minValueSpan: 3600 * 24 * 1000 * 7,
              maxValueSpan: 3600 * 24 * 1000 * 30,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: data,
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style></style>
