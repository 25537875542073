<template>
  <v-chart v-if="dataCopy.length > 0" autoresize :option="option" />
  <v-chart
    v-else
    autoresize
    :option="{
      title: {
        textStyle: {
          color: 'grey',
          fontSize: 20,
        },
        text: '無資料',
        left: 'center',
        top: 'center',
      },
    }"
  />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import { getRecordStatus } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      bpItem: {
        type: String,
        default: 'systolic',
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      const bpItemCopy = toRef(props, 'bpItem')
      const option = ref({})
      const echartInit = () => {
        const calcData = getRecordStatus(dataCopy.value, bpItemCopy.value)
        const data = [
          { value: calcData.good, name: '數值正常' },
          { value: calcData.attention, name: '數值應注意' },
          { value: calcData.abnormal, name: '數值嚴重' },
        ]
        option.value = {
          color: ['#56CCF2', '#27AE60', '#FD91B8'],
          grid: {
            left: '30',
            right: '30',
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              type: 'pie',
              radius: '80%',
              label: {
                formatter: '{b}\n{d}%',
                // position: 'inside',
              },
              data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )
      watch(
        () => props.bpItem,
        () => {
          echartInit()
        }
      )

      return {
        option,
        dataCopy,
      }
    },
  }
</script>

<style></style>
