<template>
  <div class="caseDetail-container">
    <a-page-header title="個案詳細資料">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>

    <a-tabs type="card" class="detailType" v-model:activeKey="tabKey">
      <a-tab-pane key="1" tab="基本資料">
        <BasicTab :data="caseData"></BasicTab>
      </a-tab-pane>
      <a-tab-pane key="4" tab="健康追蹤">
        <a-range-picker
          :format="dateFormat"
          style="margin-left: 20px"
          v-model:value="recordSearchData"
          :ranges="{
            前7日: [dateAdd(-8), dateAdd(-1)],
            前30日: [dateAdd(-31), dateAdd(-1)],
            前90日: [dateAdd(-91), dateAdd(-1)],
          }"
        ></a-range-picker>
        <a-button type="primary" @click="getRecord">查詢</a-button>
        <a-button
          type="primary"
          @click="exportExcel"
          :disabled="Object.keys(userRecord).length == 0"
        >
          匯出
        </a-button>
        <a-spin v-if="recordLoading" />
        <div class="caseDetail-container-basic" v-if="caseData.user">
          <div class="name">{{ caseData.user.name }}</div>
          <a-row>
            <a-col :xs="8" :sm="4" :md="2">性別</a-col>
            <a-col :xs="8" :sm="4" :md="2">
              <span v-if="caseData.user.gender === 'M'">男</span>
              <span v-else-if="caseData.user.gender === 'F'">女</span>
              <span v-else>未填寫</span>
            </a-col>
            <a-col :xs="8" :sm="4" :md="2"></a-col>
            <a-col :xs="8" :sm="4" :md="2">年齡</a-col>
            <a-col
              :xs="8"
              :sm="4"
              :md="2"
              v-if="
                caseData.user.birthday === '' ||
                caseData.user.birthday === '0000-00-00'
              "
            >
              未填寫
            </a-col>
            <a-col :xs="8" :sm="4" :md="2" v-else>
              {{ round(dateDiff(caseData.user.birthday) / 365.25, 1) }}歲
            </a-col>
            <a-col :xs="8" :sm="4" :md="2"></a-col>
          </a-row>
          <!-- <a-row>
            <a-col :xs="8" :sm="4" :md="2">身高</a-col>
            <a-col :xs="8" :sm="4" :md="2">{{ caseData.case.height }}</a-col>
            <a-col :xs="8" :sm="4" :md="2">公分</a-col>
          </a-row> -->
        </div>
        <a-tabs
          v-if="Object.keys(measurements).length"
          v-model:activeKey="measurementTabKey"
        >
          <a-tab-pane key="1">
            <template #tab>
              <span>
                <icon iconName="dashboard" />
                健康總覽
              </span>
            </template>
            <Overview :data="measurements" />
          </a-tab-pane>
          <a-tab-pane key="2">
            <template #tab>
              <icon iconName="bloodPresure" />
              <span>血壓</span>
            </template>
            <BloodPresure :data="measurements.bpMeasurements"></BloodPresure>
          </a-tab-pane>
          <a-tab-pane key="3">
            <template #tab>
              <icon iconName="bloodGlucose" />
              <span>血糖</span>
            </template>
            <BloodGlucose
              :data="measurements.glucoseMeasurements"
            ></BloodGlucose>
          </a-tab-pane>
          <a-tab-pane key="4">
            <template #tab>
              <icon iconName="sPO2" />
              <span>血氧</span>
            </template>
            <OxygenSat :data="measurements.oxygenSatMeasurements"></OxygenSat>
          </a-tab-pane>
          <a-tab-pane key="5">
            <template #tab>
              <icon iconName="weight" />
              <span>身體組成</span>
            </template>
            <Body :data="measurements.bodyWeightFatMeasurements"></Body>
          </a-tab-pane>
          <a-tab-pane key="6">
            <template #tab>
              <icon iconName="temperature" />
              <span>體溫</span>
            </template>
            <Temperature
              :data="measurements.bodyTempMeasurements"
            ></Temperature>
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
      <a-tab-pane key="2" tab="方案啟用記錄">
        <div class="search-box">
          <div style="margin-top: 10px">
            啟用日期
            <a-range-picker
              :format="dateFormat"
              style="margin-left: 20px"
              v-model:value="openHistoryForm.date"
            />
          </div>
          <div style="margin-top: 10px">
            狀態
            <a-select
              v-model:value="openHistoryForm.status"
              style="width: 120px; margin-left: 20px"
            >
              <a-select-option
                v-for="item in planStatus"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
          <a-button
            type="primary"
            @click="handleOpenHistorySearch"
            style="margin: 10px"
          >
            查詢
          </a-button>
        </div>
        <DataTable
          :searchResult="historyRecordData"
          :columns="historyRecordTableColumns"
          @action2="
            (key) => {
              receiveRecordModelForm.contractId = key
              consumableModelViewer = true
            }
          "
        />
      </a-tab-pane>
      <a-tab-pane key="3" tab="耗材領用紀錄">
        <div class="search-box">
          <div style="margin-top: 10px">
            查詢日期
            <a-range-picker
              :format="dateFormat"
              style="margin-left: 20px"
              v-model:value="receiveRecordForm.date"
            />
          </div>
          <div style="margin-top: 10px">
            方案名稱
            <a-select
              v-model:value="receiveRecordForm.contractID"
              style="min-width: 250px; margin-left: 20px"
            >
              <a-select-option :value="''">請選擇</a-select-option>
              <a-select-option
                v-for="item in allContracts"
                :key="item.id"
                :value="item.id"
              >
                {{ item.case }}({{ item.startDate }})
              </a-select-option>
            </a-select>
          </div>
          <a-button
            type="primary"
            @click="handleReceiveRecordSearch"
            style="margin: 10px"
          >
            查詢
          </a-button>
        </div>
        <ReceiveDataTable
          :searchResult="receiveRecordData"
          :columns="receiveTableColumns"
          @action1="handleReceiveInvaild"
        />
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model:visible="consumableModelViewer"
      title="領用耗材"
      @ok="handleReceive"
    >
      <a-form-item label="領用耗材">
        <a-select v-model:value="receiveRecordModelForm.receiveItemId">
          <a-select-option value="1">
            採血組(酒精棉片/採血針/試片) X 1
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="領用額度">
        <span>1點</span>
      </a-form-item>
      <a-form-item label="領用時間">
        <span>{{ parseTime(new Date()) }}</span>
      </a-form-item>
      <a-form-item label="經手人">
        <span>{{ userInfo.name }}</span>
      </a-form-item>
    </a-modal>
  </div>
</template>

<script>
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  import { onBeforeMount, reactive, ref, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { notification } from 'ant-design-vue'
  import XLSX from 'xlsx'
  import export2Excel from '@/utils/export2Excel'
  import {
    getContracts,
    getReceiveRecord,
    setReceive,
    getReceiveRecordByContractID,
    updateReceiveState,
  } from '@/api/case'
  import { getMemberRecord } from '@/api/healthy'
  import DataTable from './DataTable'
  import ReceiveDataTable from './ReceiveDataTable'
  import { getCustInfo } from '@/api/cust'
  import { getUser } from '@/api/user'
  import { checkHealthyCode } from '@/api/healthy'
  import {
    serviceStatusString,
    dateDiff,
    round,
    dateAdd,
    parseTime,
  } from '@/utils/index'
  import Overview from './Overview'
  import BasicTab from './BasicTab'
  import BloodPresure from './BloodPresure'
  import BloodGlucose from './BloodGlucose'
  import OxygenSat from './OxygenSat'
  import Body from './Body'
  import Temperature from './Temperature'

  const planStatus = [
    {
      text: '不指定',
      value: '',
    },
    {
      text: '已建立',
      value: '0',
    },
    {
      text: '進行中',
      value: '1',
    },
    {
      text: '暫停中',
      value: '2',
    },
    {
      text: '已結束',
      value: '3',
    },
    {
      text: '已停止',
      value: '4',
    },
  ]

  // 方案啟動歷史紀錄，欄位描述
  const historyRecordTableColumns = [
    {
      title: '啟動日期',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
    },
    {
      title: '方案名稱',
      key: 'case',
      align: 'center',
      dataIndex: 'case',
    },
    {
      title: '銷貨單號',
      align: 'center',
      dataIndex: 'sellId',
    },
    {
      title: '有效期限',
      key: 'expireDate',
      align: 'center',
      dataIndex: 'expireDate',
    },
    {
      title: '啟動人',
      key: 'startName',
      align: 'center',
      dataIndex: 'startName',
    },
    {
      title: '服務狀態',
      key: 'serviceStatus',
      align: 'center',
      dataIndex: 'serviceStatus',
      customRender: ({ text }) => serviceStatusString(text),
    },
    {
      title: '',
      slots: { customRender: 'action' },
    },
  ]

  // 耗材領用記錄，欄位描述
  const receiveTableColumns = [
    {
      title: '日期時間',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },
    {
      title: '方案名稱',
      dataIndex: 'case',
      key: 'case',
      align: 'center',
    },
    {
      title: '交易狀態',
      align: 'center',
      dataIndex: 'sellStatus',
      customRender: ({ text }) => sellStatue[text],
    },
    {
      title: '本次交易額度',
      key: 'useQuota',
      align: 'center',
      dataIndex: 'useQuota',
    },
    {
      title: '經辦人',
      align: 'center',
      dataIndex: 'operatorName',
    },
    {
      title: '領用門市',
      key: 'receiveStore',
      align: 'center',
      dataIndex: 'receiveStore',
    },
    {
      title: '領用耗材',
      key: 'receiveItemId',
      align: 'center',
      dataIndex: 'receiveItemId',
      customRender: ({ text }) => consumable[text],
    },
    {
      title: '',
      slots: { customRender: 'action' },
    },
  ]

  const consumable = ['', '採血針 X 1']
  const sellStatue = ['完成領用', '作廢']

  export default {
    components: {
      QuestionCircleOutlined,
      DataTable,
      ReceiveDataTable,
      Overview,
      BasicTab,
      BloodPresure,
      BloodGlucose,
      OxygenSat,
      Body,
      Temperature,
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      const userInfo = computed(() => store.getters['user/userInfo'])
      const form = reactive({})
      const openHistoryForm = reactive({ status: '' })
      const receiveRecordForm = reactive({ contractID: '' })
      const tabKey = ref(route.query.tabKey || '1') // 頁籤tab key
      const measurementTabKey = ref('1') // 量測資料tab key
      const recordLoading = ref(false) // 量測資料loading
      const measurements = ref({})
      const recordSearchData = ref([dateAdd(-30), dateAdd(-1)])
      // 方案啓動歷史記錄預設區間
      openHistoryForm.date = [dateAdd(-90), dateAdd(0)]
      // 耗材領用記錄預設區間
      receiveRecordForm.date = [dateAdd(-90), dateAdd(0)]
      const historyRecordData = ref([])
      const receiveRecordData = ref([])
      const consumableModelViewer = ref(false)
      const receiveRecordModelForm = reactive({ receiveItemId: '1' })
      const allContracts = ref([]) // 所有合約資料
      let uniqueDate = [] // 此查詢裡有幾個不同天
      const userRecord = ref({}) // 每日統計

      const caseData = ref({})
      onBeforeMount(async () => {
        // 取得會員資料
        const { data } = await getCustInfo({
          searchItem: 'memberID',
          searchValue: route.params._caseNo,
        })
        caseData.value.user = data

        // // 取得個案資料
        // const { data: contractsData } = await getContracts({
        //   searchItem: 'memberID',
        //   searchValue: route.params._caseNo,
        // })
        // caseData.value.case = contractsData.result[0]

        // 取得合約資料
        const { data: contracts } = await getContracts({
          memberID: route.params._caseNo,
        })
        allContracts.value = contracts.result
      })

      const getRecord = async () => {
        measurements.value = {}
        recordLoading.value = true
        const { data } = await getMemberRecord({
          memberID: route.params._caseNo,
          date: recordSearchData.value,
        })
        measurements.value = data.data
        recordLoading.value = false

        const { data: recordData } = await getMemberRecord({
          memberID: route.params._caseNo,
          date: recordSearchData.value,
          responseFormat: 'records',
        })

        // 每日量測資料(匯出資料預處理)
        // 抓取有幾個不同日期
        recordData.data.map((item) => {
          if (!uniqueDate.includes(item.measured.substring(0, 10)))
            uniqueDate.push(item.measured.substring(0, 10))
        })

        uniqueDate.sort()

        recordData.data.map((record) => {
          const date = record.measured.substring(0, 10)
          if (!userRecord.value[date]) {
            userRecord.value[date] = {
              pbTimes: 0,
              diastolicSum: 0,
              systolicSum: 0,
              pulsePressureDifferenceSum: 0,
              pulseSum: 0,
              afib: 0,
              arr: 0,
              pc: 0,

              osTimes: 0,
              spo2LowestSum: 0,
              pulseLowestSum: 0,

              glucoseTimes: 0,
              bloodGlucoseFastingTimes: 0,
              bloodGlucoseAfterMealTimes: 0,
              bloodGlucoseBeforeMealTimes: 0,
              bloodGlucoseFastingSum: 0,
              bloodGlucoseAfterMealSum: 0,
              bloodGlucoseBeforeMealSum: 0,

              bodyTempTimes: 0,
              bodyTempSum: 0,

              bodyTimes: 0,
              weightSum: 0,
              bmiSum: 0,
              bmrSum: 0,
              bodyFatSum: 0,
              muscleMassSum: 0,
              visceralFatSum: 0,
            }
          }
          // 血壓統計
          if (record.bpMeasurements.length > 0) {
            userRecord.value[date].pbTimes++
            userRecord.value[date].diastolicSum +=
              record.bpMeasurements[0]?.diastolic
            userRecord.value[date].systolicSum +=
              record.bpMeasurements[0]?.systolic
            userRecord.value[date].pulsePressureDifferenceSum +=
              record.bpMeasurements[0]?.pulsePressureDifference
            userRecord.value[date].pulseSum += record.bpMeasurements[0]?.pulse
            if (record.bpMeasurements[0]?.afib) userRecord.value[date].afib++
            if (record.bpMeasurements[0]?.arr) userRecord.value[date].arr++
            if (record.bpMeasurements[0]?.pc) userRecord.value[date].pc++
          }

          // 血氧統計
          if (record.oxygenSatMeasurements.length > 0) {
            userRecord.value[date].osTimes++
            userRecord.value[date].spo2LowestSum +=
              record.oxygenSatMeasurements[0]?.spo2Lowest
            userRecord.value[date].pulseLowestSum +=
              record.oxygenSatMeasurements[0]?.pulseLowest
          }

          // 血糖統計
          if (record.glucoseMeasurements.length > 0) {
            userRecord.value[date].glucoseTimes++
            switch (record.glucoseMeasurements[0]?.meal) {
              case 'Fasting':
                userRecord.value[date].bloodGlucoseFastingTimes++
                userRecord.value[date].bloodGlucoseFastingSum +=
                  record.glucoseMeasurements[0]?.bloodGlucose
                break
              case 'AfterMeal':
                userRecord.value[date].bloodGlucoseAfterMealTimes++
                userRecord.value[date].bloodGlucoseAfterMealSum +=
                  record.glucoseMeasurements[0]?.bloodGlucose
                break
              case 'BeforeMeal':
                userRecord.value[date].bloodGlucoseBeforeMealTimes++
                userRecord.value[date].bloodGlucoseBeforeMealSum +=
                  record.glucoseMeasurements[0]?.bloodGlucose
                break
            }
          }

          // 體溫統計
          if (record.bodyTempMeasurements.length > 0) {
            userRecord.value[date].bodyTempTimes++
            userRecord.value[date].bodyTempSum +=
              record.bodyTempMeasurements[0]?.bodyTemperature
          }

          // 身體組成
          if (record.bodyWeightFatMeasurements.length > 0) {
            userRecord.value[date].bodyTimes++
            userRecord.value[date].weightSum +=
              record.bodyWeightFatMeasurements[0]?.weight
            userRecord.value[date].bmiSum +=
              record.bodyWeightFatMeasurements[0]?.bmi
            userRecord.value[date].bmrSum +=
              record.bodyWeightFatMeasurements[0]?.bmr
            userRecord.value[date].bodyFatSum +=
              record.bodyWeightFatMeasurements[0]?.bodyFat
            userRecord.value[date].muscleMassSum +=
              record.bodyWeightFatMeasurements[0]?.muscleMass
            userRecord.value[date].visceralFatSum +=
              record.bodyWeightFatMeasurements[0]?.visceralFat
          }
        })
      }

      // 匯出量測資料
      const exportExcel = async () => {
        const aoa = [
          [
            '量測日期',
            '會員卡號',
            '會員姓名',
            '血壓量測次數',
            '平均收縮壓',
            '平均舒張壓',
            '平均脈壓差',
            '平均脈搏',
            '心律不整(ARR)次數',
            '早期收縮(PC)次數',
            '心房顫動(Afib)次數',
            '血糖量測次數',
            '平均空腹血糖',
            '平均飯前血糖',
            '平均飯後血糖',
            '血氧量測次數',
            '平均最低血氧',
            '平均脈搏',
            '體溫量測次數',
            '平均體溫',
            '身體組成量測次數',
            '平均體重',
            '平均BMI',
            '平均體脂率',
            '平均基礎代謝率',
            '平均內臟脂肪',
            '平均肌肉率',
          ],
        ]
        for (const date in userRecord.value) {
          aoa.push([
            date,
            caseData.value.user.cardId.substring(0, 10),
            caseData.value.user.name,
            userRecord.value[date].pbTimes || '',
            round(
              userRecord.value[date].diastolicSum /
                userRecord.value[date].pbTimes
            ),
            round(
              userRecord.value[date].systolicSum /
                userRecord.value[date].pbTimes
            ),
            round(
              userRecord.value[date].pulsePressureDifferenceSum /
                userRecord.value[date].pbTimes
            ),
            round(
              userRecord.value[date].pulseSum / userRecord.value[date].pbTimes
            ),
            userRecord.value[date].arr || '',
            userRecord.value[date].pc || '',
            userRecord.value[date].afib || '',
            userRecord.value[date].glucoseTimes || '',
            round(
              userRecord.value[date].bloodGlucoseFastingSum /
                userRecord.value[date].bloodGlucoseFastingTimes
            ),
            round(
              userRecord.value[date].bloodGlucoseAfterMealSum /
                userRecord.value[date].bloodGlucoseAfterMealTimes
            ),
            round(
              userRecord.value[date].bloodGlucoseBeforeMealSum /
                userRecord.value[date].bloodGlucoseBeforeMealTimes
            ),
            userRecord.value[date].osTimes || '',
            round(
              userRecord.value[date].spo2LowestSum /
                userRecord.value[date].osTimes
            ),
            round(
              userRecord.value[date].pulseLowestSum /
                userRecord.value[date].osTimes
            ),
            userRecord.value[date].bodyTempTimes || '',
            round(
              userRecord.value[date].bodyTempSum /
                userRecord.value[date].bodyTempTimes,
              1
            ),
            userRecord.value[date].bodyTimes || '',
            round(
              userRecord.value[date].weightSum /
                userRecord.value[date].bodyTimes,
              1
            ),
            round(
              userRecord.value[date].bmiSum / userRecord.value[date].bodyTimes,
              1
            ),
            round(
              userRecord.value[date].bodyFatSum /
                userRecord.value[date].bodyTimes,
              1
            ),
            round(
              userRecord.value[date].bmrSum / userRecord.value[date].bodyTimes
            ),
            round(
              userRecord.value[date].visceralFatSum /
                userRecord.value[date].bodyTimes
            ),
            round(
              userRecord.value[date].muscleMassSum /
                userRecord.value[date].bodyTimes,
              1
            ),
          ])
        }

        let worksheet1 = XLSX.utils.aoa_to_sheet(aoa)
        const fileDate =
          parseTime(
            new Date(recordSearchData.value[0]).getTime(),
            '{y}{m}{d}'
          ) +
          'TO' +
          parseTime(new Date(recordSearchData.value[1]).getTime(), '{y}{m}{d}')
        export2Excel({
          worksheets: {
            sheet1: worksheet1,
          }, // 匯出excel的資料，key表示工作表名，value表示對應工作表的 sheet 資料，支援匯出多個工作表
          fileName: `DailyreportforCase_${fileDate}`, // 匯出檔名
          type: 'xlsx', // 檔案匯出型別
        })
      }

      const handleOpenHistorySearch = async () => {
        const { data } = await getContracts({
          ...openHistoryForm,
          memberID: route.params._caseNo,
        })
        const dataList = data.result

        // 已啓動碼查詢銷貨單號
        for (const key in dataList) {
          const { data } = await checkHealthyCode({
            code: dataList[key].activationCode,
          })
          // 啟動碼正確
          if (data.found) {
            dataList[key].sellId = data.sellId
          }
        }

        if (typeof dataList === 'undefined' || dataList.length === 0) {
          historyRecordData.value = []
        } else {
          dataList.map((item) => {
            switch (item.serviceStatus) {
              case 1: // 進行中
                item.action1 = '暫停方案'
                item.action2 = '領用耗材'
                break
              case 2: // 暫停中
                item.action3 = '繼續使用'
                break
              default:
                break
            }
            item.key = item.id
          })
          historyRecordData.value = dataList
        }
      }

      const handleReceiveRecordSearch = async () => {
        // 未選擇合約
        if (openHistoryForm.contractID == '') {
          const data = await getReceiveRecord({
            ...openHistoryForm,
            memberID: route.params._caseNo,
          })
          const dataList = data.data.result
          if (typeof dataList === 'undefined' || dataList.length === 0) {
            receiveRecordData.value = []
          } else {
            for (const key in dataList) {
              dataList[key].action1 = '作廢'
              dataList[key].key = dataList[key].id
              const { data } = await getUser({
                loginName: dataList[key].operatorId,
              })
              dataList[key].operatorName = data.name
            }
            receiveRecordData.value = dataList
          }
        } else {
          // 有選擇合約
          const data = await getReceiveRecordByContractID({
            ...openHistoryForm,
          })
          const dataList = data.data.result
          if (typeof dataList === 'undefined' || dataList.length === 0) {
            receiveRecordData.value = []
          } else {
            for (const key in dataList) {
              dataList[key].action1 = '作廢'
              dataList[key].key = dataList[key].id
              const { data } = await getUser({
                loginName: dataList[key].operatorId,
              })
              dataList[key].operatorName = data.name
            }
            receiveRecordData.value = dataList
          }
        }
      }

      // 領用耗材
      const handleReceive = async () => {
        const { data } = await setReceive({
          contractID: receiveRecordModelForm.contractId,
          goodsID: parseInt(receiveRecordModelForm.receiveItemId),
          pic: userInfo.value.account,
        })
        if (data.ok) {
          notification.success({
            message: '領用成功',
          })
          consumableModelViewer.value = false
        } else {
          notification.error({
            message: '領用失敗',
          })
        }
      }

      // 領用耗材作廢
      const handleReceiveInvaild = async (id) => {
        const { data } = await updateReceiveState({ id, state: 0 })
        if (data.ok) {
          notification.success({
            message: '作廢完成',
          })
          const record = receiveRecordData.value.find((item) => item.id == id)
          record.sellStatus = 1
        } else {
          notification.error({
            message: '作廢失敗',
          })
        }
      }

      return {
        dateFormat: 'YYYY/MM/DD',
        form,
        openHistoryForm,
        receiveRecordForm,
        caseData,
        userRecord,
        historyRecordTableColumns,
        historyRecordData,
        receiveTableColumns,
        receiveRecordData,
        getRecord,
        exportExcel,
        recordSearchData,
        tabKey,
        measurementTabKey,
        measurements,
        dateDiff,
        round,
        recordLoading,
        handleOpenHistorySearch,
        handleReceiveRecordSearch,
        handleReceive,
        planStatus,
        consumableModelViewer,
        userInfo,
        parseTime,
        receiveRecordModelForm,
        allContracts,
        handleReceiveInvaild,
        dateAdd,
      }
    },
  }
</script>

<style lang="less">
  .caseDetail-container {
    .ant-tabs-card.detailType {
      > .ant-tabs-bar {
        .ant-tabs-tab {
          border: 1px solid #6e6e6e;
          background: transparent;
        }
        .ant-tabs-tab-active {
          border-top: 3px solid #000000;
          box-sizing: border-box;
          border-bottom: #fff;
          background: #fff;
        }
      }
    }
    .ant-tabs-content {
      margin-top: -16px;
      .ant-tabs-tabpane {
        background: #fff;
        padding: 16px;
      }
    }

    .ant-form {
      background-color: #fff;
      .ant-form-item {
        margin-bottom: 16px;
      }
    }

    &-basic {
      background-color: #f2f2f2;
      border-radius: 5px;
      padding: 12px 24px;
      font-size: 16px;
      .name {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .bp-type {
      .ant-tabs-top-content {
        height: 0;
      }
    }
  }
  .ant-modal {
    .ant-modal-title {
      font-size: 26px;
    }
    label {
      font-size: 16px;
    }
  }
</style>
