import { render } from "./Temperature.vue?vue&type=template&id=4fd6552c&scoped=true"
import script from "./Temperature.vue?vue&type=script&lang=js"
export * from "./Temperature.vue?vue&type=script&lang=js"

import "./Temperature.vue?vue&type=style&index=0&id=4fd6552c&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-4fd6552c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4fd6552c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4fd6552c', script)) {
    api.reload('4fd6552c', script)
  }
  
  module.hot.accept("./Temperature.vue?vue&type=template&id=4fd6552c&scoped=true", () => {
    api.rerender('4fd6552c', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/Temperature.vue"

export default script