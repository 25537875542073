import { render } from "./CaseGlucose.vue?vue&type=template&id=c7401858"
import script from "./CaseGlucose.vue?vue&type=script&lang=js"
export * from "./CaseGlucose.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c7401858"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c7401858', script)) {
    api.reload('c7401858', script)
  }
  
  module.hot.accept("./CaseGlucose.vue?vue&type=template&id=c7401858", () => {
    api.rerender('c7401858', render)
  })

}

script.__file = "src/components/charts/CaseGlucose.vue"

export default script