import { render } from "./OsRecordDataTable.vue?vue&type=template&id=f0ce06b2&scoped=true"
import script from "./OsRecordDataTable.vue?vue&type=script&lang=js"
export * from "./OsRecordDataTable.vue?vue&type=script&lang=js"

import "./OsRecordDataTable.vue?vue&type=style&index=0&id=f0ce06b2&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-f0ce06b2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "f0ce06b2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f0ce06b2', script)) {
    api.reload('f0ce06b2', script)
  }
  
  module.hot.accept("./OsRecordDataTable.vue?vue&type=template&id=f0ce06b2&scoped=true", () => {
    api.rerender('f0ce06b2', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/OsRecordDataTable.vue"

export default script