<template>
  <a-row :gutter="48">
    <a-col :span="24">
      <CaseGlucose :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <CaseGlucoseTimeAvg :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <a-tabs class="bp-type" v-model:activeKey="glucoseTypeKey">
        <a-tab-pane key="fasting" tab="空腹"></a-tab-pane>
        <a-tab-pane key="before meal" tab="餐前"></a-tab-pane>
        <a-tab-pane key="after meal" tab="餐後"></a-tab-pane>
      </a-tabs>
      <CaseGlucosePercentage
        :data="measurements"
        :glucoseItem="glucoseTypeKey"
      />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <GlucoseAvgTable :records="measurements" />
    </a-col>
  </a-row>
  <GlucoseRecordDataTable :records="measurements" />
</template>

<script>
  import { ref, toRef } from 'vue'
  import CaseGlucose from '@/components/charts/CaseGlucose'
  import CaseGlucoseTimeAvg from '@/components/charts/CaseGlucoseTimeAvg'
  import CaseGlucosePercentage from '@/components/charts/CaseGlucosePercentage'
  import GlucoseAvgTable from '@/views/healthManagement/caseManagement/caseDetail/GlucoseAvgTable'
  import GlucoseRecordDataTable from '@/views/healthManagement/caseManagement/caseDetail/GlucoseRecordDataTable'
  export default {
    components: {
      CaseGlucose,
      CaseGlucoseTimeAvg,
      CaseGlucosePercentage,
      GlucoseAvgTable,
      GlucoseRecordDataTable,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const measurements = toRef(props, 'data')
      const glucoseTypeKey = ref('Fasting') // 血糖異常比例tab key

      return {
        measurements,
        glucoseTypeKey,
      }
    },
  }
</script>

<style lang="less" scoped>
  .ant-col {
    height: 400px;
  }
</style>
