import { render } from "./TempRecordDataTable.vue?vue&type=template&id=25fb4e92&scoped=true"
import script from "./TempRecordDataTable.vue?vue&type=script&lang=js"
export * from "./TempRecordDataTable.vue?vue&type=script&lang=js"

import "./TempRecordDataTable.vue?vue&type=style&index=0&id=25fb4e92&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-25fb4e92"
/* hot reload */
if (module.hot) {
  script.__hmrId = "25fb4e92"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25fb4e92', script)) {
    api.reload('25fb4e92', script)
  }
  
  module.hot.accept("./TempRecordDataTable.vue?vue&type=template&id=25fb4e92&scoped=true", () => {
    api.rerender('25fb4e92', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/TempRecordDataTable.vue"

export default script