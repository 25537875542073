<template>
  <div class="table-container">
    <div v-if="data.length > 0" id="table-count">
      總共筆數：{{ dataLength }}
    </div>
    <a-table
      :pagination="tableSetting"
      :bordered="true"
      :columns="tableColumns"
      :data-source="data"
      :rowKey="(record) => record.measurement.measured"
      :scroll="{ x: 600, y: windowHeight - 110 }"
      size="small"
    >
      <template #action="{ record }">
        <a @click="action1(record.measurement.id)">
          {{ parseTime(record.measurement.measured) }}
        </a>
      </template>
      <template #weight>
        <span style="font-size: 16px">體重</span>
        <br />
        <span style="font-size: 14px; color: #828282">(公斤)</span>
      </template>

      <template #bmiValue="{ text }">
        <span v-if="text >= 30" class="abnormal">{{ round(text, 1) }}</span>
        <span
          v-else-if="(text >= 25 && text <= 29.9) || text < 18.5"
          class="attention"
        >
          {{ round(text, 1) }}
        </span>
        <span v-else>{{ round(text, 1) }}</span>
      </template>

      <template #bmr>
        <span style="font-size: 16px">基礎代謝率</span>
        <br />
        <span style="font-size: 14px; color: #828282">(大卡)</span>
      </template>

      <template #visceralFat>
        <span style="font-size: 16px">內臟脂肪</span>
        <br />
        <span style="font-size: 14px; color: #828282">(等級)</span>
      </template>
      <template #visceralFatValue="{ text }">
        <span v-if="text >= 15 && text <= 30" class="abnormal">{{ text }}</span>
        <span v-else-if="text >= 10 && text <= 14.5" class="attention">
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #bodyFat>
        <span style="font-size: 16px">體脂率</span>
        <br />
        <span style="font-size: 14px; color: #828282">(%)</span>
      </template>

      <template #muscleMass>
        <span style="font-size: 16px">肌肉率</span>
        <br />
        <span style="font-size: 14px; color: #828282">(%)</span>
      </template>
    </a-table>
  </div>
</template>

<script>
  import { reactive, computed, toRef } from 'vue'
  import { numberComma, parseTime, round } from '@/utils/index'
  export default {
    props: {
      records: {
        type: Array,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      const data = toRef(props, 'records')
      const windowHeight = window.innerHeight
      const dataLength = computed(() => numberComma(data.value.length))
      const tableSetting = reactive({
        currentPage: 1,
        pageSize: 20,
        position: 'top',
        simple: true,
      })
      const tableColumns = [
        {
          title: '量測日期',
          width: 240,
          dataIndex: 'measurement.measured',
          key: 'date',
          fixed: 'left',
          align: 'center',
          type: 'all',
          slots: { customRender: 'action' },
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            return ('' + a.measurement.measured).localeCompare(
              b.measurement.measured
            )
          },
        },
        {
          dataIndex: 'measurement.weight',
          key: 'weight',
          slots: {
            title: 'weight',
          },
          align: 'center',
          customRender: ({ text }) => round(text, 1),
        },
        {
          dataIndex: 'measurement.bmi',
          key: 'bmi',
          title: 'BMI',
          slots: {
            customRender: 'bmiValue',
          },
          align: 'center',
        },
        {
          dataIndex: 'measurement.bmr',
          key: 'bmr',
          slots: {
            title: 'bmr',
          },
          align: 'center',
          customRender: ({ text }) => round(text),
        },
        {
          dataIndex: 'measurement.visceralFat',
          key: 'visceralFat',
          slots: {
            title: 'visceralFat',
            customRender: 'visceralFatValue',
          },
          align: 'center',
        },
        {
          dataIndex: 'measurement.bodyFat',
          key: 'bodyFat',
          slots: {
            title: 'bodyFat',
          },
          align: 'center',
          customRender: ({ text }) => round(text),
        },
        {
          dataIndex: 'measurement.muscleMass',
          key: 'muscleMass',
          slots: {
            title: 'muscleMass',
          },
          align: 'center',
          customRender: ({ text }) => round(text),
        },
      ]

      const action1 = (value) => {
        emit('action1', value)
      }

      return {
        tableColumns,
        tableSetting,
        data,
        dataLength,
        action1,
        windowHeight,
        parseTime,
        round,
      }
    },
  }
</script>

<style lang="less" scoped>
  .table-container {
    position: relative;
    #table-count {
      position: absolute;
      right: 200px;
      top: 10px;
    }
  }
  .ant-pagination.ant-table-pagination {
    float: right;
    text-align: right;
    margin: -25px 0 0 0;
  }

  .ant-table-thead > tr {
    th {
      text-align: center;
      height: 60px;
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px;
  }
  .abnormal {
    color: #8b2331;
  }
  .attention {
    color: #f08719;
  }
</style>
