import { render } from "./GlucoseAvgTable.vue?vue&type=template&id=7830b6e2&scoped=true"
import script from "./GlucoseAvgTable.vue?vue&type=script&lang=js"
export * from "./GlucoseAvgTable.vue?vue&type=script&lang=js"

import "./GlucoseAvgTable.vue?vue&type=style&index=0&id=7830b6e2&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-7830b6e2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7830b6e2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7830b6e2', script)) {
    api.reload('7830b6e2', script)
  }
  
  module.hot.accept("./GlucoseAvgTable.vue?vue&type=template&id=7830b6e2&scoped=true", () => {
    api.rerender('7830b6e2', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/GlucoseAvgTable.vue"

export default script