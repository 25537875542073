<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  // import { getChartYAxisRange, measurementAvg } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      const option = ref(null)
      const echartInit = () => {
        let uniqueLabel = [] // 共有幾個不同的血氧數值

        const labelMin = Math.min.apply(
          Math,
          dataCopy.value.map((o) => o.measurement.spo2Lowest)
        )
        const dataGood = []
        const dataAttention = []
        const dataAbnormal = []
        for (var i = labelMin; i <= 100; i++) {
          uniqueLabel.push(i)
          if (i <= 89) {
            dataGood.push('-')
            dataAttention.push('-')
            dataAbnormal.push(
              dataCopy.value.filter((item) => item.measurement.spo2Lowest == i)
                .length
            )
          } else if (i <= 93) {
            dataGood.push('-')
            dataAttention.push(
              dataCopy.value.filter((item) => item.measurement.spo2Lowest == i)
                .length
            )
            dataAbnormal.push('-')
          } else {
            dataGood.push(
              dataCopy.value.filter((item) => item.measurement.spo2Lowest == i)
                .length
            )
            dataAttention.push('-')
            dataAbnormal.push('-')
          }
        }
        uniqueLabel.reverse()

        option.value = {
          title: {
            text: '血氧狀態累計',
            textStyle: {
              fontSize: '20',
            },
            left: 'center',
          },
          color: ['#8B2331', '#DFB044', '#828282'],
          grid: {
            left: '30',
            right: '30',
          },
          tooltip: {
            trigger: 'item',
            formatter: (params) => {
              return `<span class="chart-tooltip-point" style="background-color:${params.color};"></span>
                血氧值:   ${params.name}%<br/>
                紀錄筆數: ${params.value} 筆<br/>`
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['嚴重', '應注意', '正常'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'category',
              data: uniqueLabel,
              name: '%',

              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '次',
              min: 0,
              interval: 1,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          series: [
            {
              name: '嚴重',
              data: dataAbnormal.reverse(),
              stack: 'count',
              type: 'bar',
            },
            {
              name: '應注意',
              data: dataAttention.reverse(),
              stack: 'count',
              type: 'bar',
            },
            {
              name: '正常',
              data: dataGood.reverse(),
              stack: 'count',
              type: 'bar',
            },
          ],
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style></style>
