<template>
  <v-chart v-if="dataLength > 0" autoresize :option="option" />
  <v-chart
    v-else
    autoresize
    :option="{
      title: {
        textStyle: {
          color: 'grey',
          fontSize: 20,
        },
        text: '無資料',
        left: 'center',
        top: 'center',
      },
    }"
  />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import { getRecordStatus, mealString } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      glucoseItem: {
        type: String,
        default: 'Fasting',
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      const glucoseItemCopy = toRef(props, 'glucoseItem')
      const dataLength = ref(0)
      const option = ref(null)
      const echartInit = () => {
        const itemValues = dataCopy.value
          .filter(
            (item) =>
              item.measurement.meal.toLowerCase() == glucoseItemCopy.value
          )
          .map((item) => item.measurement.bloodGlucose)
        dataLength.value = itemValues.length
        const calcData = getRecordStatus(itemValues, glucoseItemCopy.value)
        const data = [
          { value: calcData.good, name: '數值正常' },
          { value: calcData.attention, name: '數值應注意' },
          { value: calcData.abnormal, name: '數值嚴重' },
        ]
        option.value = {
          title: {
            text: `血糖狀態分布 - ${mealString(glucoseItemCopy.value)}`,
            textStyle: {
              fontSize: '20',
            },
            left: 'center',
          },
          // title: {
          //   show: dataCopy.value.length === 0,
          //   textStyle: {
          //     color: 'grey',
          //     fontSize: 20,
          //   },
          //   text: '無資料',
          //   left: 'center',
          //   top: 'center',
          // },
          color: ['#56CCF2', '#27AE60', '#FD91B8'],
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              type: 'pie',
              radius: '70%',
              label: {
                formatter: '{b}\n{d}%',
                // position: 'inside',
              },
              data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )
      watch(
        () => props.glucoseItem,
        () => {
          echartInit()
        }
      )

      return {
        option,
        dataLength,
      }
    },
  }
</script>

<style></style>
