import { render } from "./BloodGlucose.vue?vue&type=template&id=507e220c&scoped=true"
import script from "./BloodGlucose.vue?vue&type=script&lang=js"
export * from "./BloodGlucose.vue?vue&type=script&lang=js"

import "./BloodGlucose.vue?vue&type=style&index=0&id=507e220c&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-507e220c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "507e220c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('507e220c', script)) {
    api.reload('507e220c', script)
  }
  
  module.hot.accept("./BloodGlucose.vue?vue&type=template&id=507e220c&scoped=true", () => {
    api.rerender('507e220c', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/BloodGlucose.vue"

export default script