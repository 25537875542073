import { render } from "./BloodPresure.vue?vue&type=template&id=88850b54&scoped=true"
import script from "./BloodPresure.vue?vue&type=script&lang=js"
export * from "./BloodPresure.vue?vue&type=script&lang=js"

import "./BloodPresure.vue?vue&type=style&index=0&id=88850b54&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-88850b54"
/* hot reload */
if (module.hot) {
  script.__hmrId = "88850b54"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('88850b54', script)) {
    api.reload('88850b54', script)
  }
  
  module.hot.accept("./BloodPresure.vue?vue&type=template&id=88850b54&scoped=true", () => {
    api.rerender('88850b54', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/BloodPresure.vue"

export default script