import { render } from "./_caseNo.vue?vue&type=template&id=63663008"
import script from "./_caseNo.vue?vue&type=script&lang=js"
export * from "./_caseNo.vue?vue&type=script&lang=js"

import "./_caseNo.vue?vue&type=style&index=0&id=63663008&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "63663008"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('63663008', script)) {
    api.reload('63663008', script)
  }
  
  module.hot.accept("./_caseNo.vue?vue&type=template&id=63663008", () => {
    api.rerender('63663008', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/_caseNo.vue"

export default script