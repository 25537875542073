<template>
  <div>
    <a-table
      :bordered="true"
      :columns="tableColumns"
      :data-source="data"
      rowKey="name"
      :pagination="false"
    >
      <template #bodyTemperature>
        <span style="font-size: 16px">體溫</span>
        <br />
        <span style="font-size: 14px; color: #828282">(度)</span>
      </template>

      <template #tempValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 38.1" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 37.1 && text <= 38) || text < 35.9"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
  const tableColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '40%',
    },
    {
      dataIndex: 'bodyTemperature',
      key: 'bodyTemperature',
      slots: {
        title: 'bodyTemperature',
        customRender: 'tempValue',
      },
      align: 'center',
    },
  ]
  import { toRef, watch, onMounted, ref } from 'vue'
  import { measurementAvg, round } from '@/utils/index'
  export default {
    props: {
      records: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'records')
      const data = ref([])
      const tableInit = () => {
        // 期間平均值
        data.value = [
          {
            name: '期間平均值',
            bodyTemperature: measurementAvg(
              dataCopy.value,
              'bodyTemperature',
              1
            ),
          },
          {
            name: '期間最高值',
            bodyTemperature: round(
              Math.max.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bodyTemperature)
              ),
              1
            ),
          },
          {
            name: '期間最低值',
            bodyTemperature: round(
              Math.min.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bodyTemperature)
              ),
              1
            ),
          },
        ]
      }

      onMounted(() => {
        tableInit()
      })

      watch(
        () => props.records,
        () => {
          tableInit()
        }
      )

      return {
        data,
        tableColumns,
      }
    },
  }
</script>

<style lang="less" scoped>
  .abnormal {
    color: #8b2331;
  }
  .attention {
    color: #f08719;
  }
</style>
