import { render } from "./CaseOsPercentage.vue?vue&type=template&id=052043a8"
import script from "./CaseOsPercentage.vue?vue&type=script&lang=js"
export * from "./CaseOsPercentage.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "052043a8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('052043a8', script)) {
    api.reload('052043a8', script)
  }
  
  module.hot.accept("./CaseOsPercentage.vue?vue&type=template&id=052043a8", () => {
    api.rerender('052043a8', render)
  })

}

script.__file = "src/components/charts/CaseOsPercentage.vue"

export default script