<template>
  <a-row :gutter="48">
    <a-col :span="24">
      <CaseTemp :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <TempAvgTable :records="measurements" />
    </a-col>
  </a-row>
  <TempRecordDataTable :records="measurements" />
</template>

<script>
  import { toRef } from 'vue'
  import CaseTemp from '@/components/charts/CaseTemp'
  import TempAvgTable from '@/views/healthManagement/caseManagement/caseDetail/TempAvgTable'
  import TempRecordDataTable from '@/views/healthManagement/caseManagement/caseDetail/TempRecordDataTable'
  export default {
    components: {
      CaseTemp,
      TempAvgTable,
      TempRecordDataTable,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const measurements = toRef(props, 'data')
      return {
        measurements,
      }
    },
  }
</script>

<style lang="less" scoped>
  .ant-col {
    height: 400px;
  }
</style>
