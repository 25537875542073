<template>
  <div>
    <a-table
      :bordered="true"
      :columns="tableColumns"
      :data-source="data"
      rowKey="name"
      :pagination="false"
    >
      <template #systolic>
        <span style="font-size: 16px">收縮壓</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫米汞柱)</span>
      </template>
      <template #systolicValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 180" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 30 && text <= 89) || (text >= 140 && text <= 179)"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #diastolic>
        <span style="font-size: 16px">舒張壓</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫米汞柱)</span>
      </template>
      <template #diastolicValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 110" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 30 && text <= 59) || (text >= 90 && text <= 109)"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #pulsePressureDiff>
        <span style="font-size: 16px">脈壓差</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫米汞柱)</span>
      </template>
      <template #pulsePressureDiffValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 60 || text <= 20" class="abnormal">
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #pulse>
        <span style="font-size: 16px">脈搏</span>
        <br />
        <span style="font-size: 14px; color: #828282">(次/分鐘)</span>
      </template>
      <template #pulseValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 150" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 40 && text <= 59) || (text >= 101 && text <= 149)"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
  const tableColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      dataIndex: 'systolic',
      key: 'systolic',
      slots: {
        title: 'systolic',
        customRender: 'systolicValue',
      },
      align: 'center',
    },
    {
      dataIndex: 'diastolic',
      key: 'diastolic',
      slots: {
        title: 'diastolic',
        customRender: 'diastolicValue',
      },
      align: 'center',
    },
    {
      dataIndex: 'pulsePressureDiff',
      key: 'pulsePressureDiff',
      slots: {
        title: 'pulsePressureDiff',
        customRender: 'pulsePressureDiffValue',
      },
      align: 'center',
    },
    {
      dataIndex: 'pulse',
      key: 'pulse',
      slots: {
        title: 'pulse',
        customRender: 'pulseValue',
      },
      align: 'center',
    },
  ]
  import { toRef, watch, onMounted, ref } from 'vue'
  import { measurementAvg } from '@/utils/index'
  export default {
    props: {
      records: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'records')
      const data = ref([])
      const tableInit = () => {
        // 期間平均值
        data.value = [
          {
            name: '期間平均值',
            systolic: measurementAvg(dataCopy.value, 'systolic'),
            diastolic: measurementAvg(dataCopy.value, 'diastolic'),
            pulsePressureDiff: measurementAvg(
              dataCopy.value,
              'pulsePressureDifference'
            ),
            pulse: measurementAvg(dataCopy.value, 'pulse'),
          },
          {
            name: '期間最高值',
            systolic: Math.max.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.systolic)
            ),
            diastolic: Math.max.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.diastolic)
            ),
            pulsePressureDiff: Math.max.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.pulsePressureDifference)
            ),
            pulse: Math.max.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.pulse)
            ),
          },
          {
            name: '期間最低值',
            systolic: Math.min.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.systolic)
            ),
            diastolic: Math.min.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.diastolic)
            ),
            pulsePressureDiff: Math.min.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.pulsePressureDifference)
            ),
            pulse: Math.min.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.pulse)
            ),
          },
        ]
      }

      onMounted(() => {
        tableInit()
      })

      watch(
        () => props.records,
        () => {
          tableInit()
        }
      )

      return {
        data,
        tableColumns,
      }
    },
  }
</script>

<style lang="less" scoped>
  .abnormal {
    color: #8b2331;
  }
  .attention {
    color: #f08719;
  }
</style>
