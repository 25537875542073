import { render } from "./BpAvgTable.vue?vue&type=template&id=3f6f6dfc&scoped=true"
import script from "./BpAvgTable.vue?vue&type=script&lang=js"
export * from "./BpAvgTable.vue?vue&type=script&lang=js"

import "./BpAvgTable.vue?vue&type=style&index=0&id=3f6f6dfc&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-3f6f6dfc"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3f6f6dfc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3f6f6dfc', script)) {
    api.reload('3f6f6dfc', script)
  }
  
  module.hot.accept("./BpAvgTable.vue?vue&type=template&id=3f6f6dfc&scoped=true", () => {
    api.rerender('3f6f6dfc', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/BpAvgTable.vue"

export default script