<template>
  <div class="overview-item">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col class="icon" flex="0 0 105px">
        <icon :iconName="props.icon" :customSize="'3rem'" />
      </a-col>
      <a-col
        flex="1 1 10px"
        class="flex-column"
        v-for="item in recordData"
        :key="item.title"
      >
        <div class="title">{{ item.title }}</div>
        <div v-if="!isFinite(item.value)"></div>
        <div
          class="value"
          v-else-if="typeof item.value === 'number' && !isNaN(item.value)"
          :style="{ color: item.color }"
        >
          {{ item.value }}
        </div>
        <div
          class="fractions"
          v-else-if="typeof item.value == 'object'"
          :style="{ color: item.color }"
        >
          <sup>{{ item.value[0] }}</sup>
          &frasl;
          <sub>{{ item.value[1] }}</sub>
        </div>
        <div class="unit" v-if="item.unit">{{ item.unit }}</div>
      </a-col>

      <!-- 未滿8個補齊 -->
      <a-col
        flex="1 1 10px"
        class="flex-column"
        v-for="i in 8 - recordData.length"
        :key="i"
      ></a-col>

      <a-col flex="0 0 150px">
        <span class="msg" v-if="props.message">{{ props.message }}</span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: 'bloodPresure',
      },
      recordData: {
        type: Array,
        default: function () {
          return []
        },
      },
      message: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      return {
        props,
      }
    },
  }
</script>

<style lang="less" scoped>
  .overview-item {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
    border-radius: 10px;
    height: 90px;
    margin-bottom: 28px;

    .icon {
      position: relative;
      .svg-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #8b2331;
      }
      height: 90px;
      background: #f7f6f6;
      border-radius: 10px;
    }
    .flex-column {
      height: 90px;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: space-between;
      align-items: center;
      .title {
        margin-top: 2px;
        font-size: 16px;
        line-height: 23px;
      }
      .value {
        position: absolute;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .fractions {
        position: absolute;
        font-size: 36px;
        line-height: 28px;
        font-weight: bold;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
      }
      .unit {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 2px;
        color: #c4c4c4;
      }
    }

    .msg {
      font-size: 20px;
      color: #8b2331;
      border-left: 3.5px #8b2331 solid;
      padding-left: 5px;
    }
  }
</style>
