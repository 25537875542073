import { render } from "./BpRecordDataTable.vue?vue&type=template&id=2cef01fd&scoped=true"
import script from "./BpRecordDataTable.vue?vue&type=script&lang=js"
export * from "./BpRecordDataTable.vue?vue&type=script&lang=js"

import "./BpRecordDataTable.vue?vue&type=style&index=0&id=2cef01fd&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-2cef01fd"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2cef01fd"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2cef01fd', script)) {
    api.reload('2cef01fd', script)
  }
  
  module.hot.accept("./BpRecordDataTable.vue?vue&type=template&id=2cef01fd&scoped=true", () => {
    api.rerender('2cef01fd', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/BpRecordDataTable.vue"

export default script