<template>
  <div>
    <a-table
      :bordered="true"
      :columns="tableColumns"
      :data-source="data"
      rowKey="name"
      :pagination="false"
    >
      <template #fasting>
        <span style="font-size: 16px">空腹</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫克/分升)</span>
      </template>
      <template #fastingValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 126" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 100 && text <= 125) || text <= 69"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #preprandial>
        <span style="font-size: 16px">餐前</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫克/分升)</span>
      </template>

      <template #postprandial>
        <span style="font-size: 16px">餐後</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫克/分升)</span>
      </template>
      <template #postprandialValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 200" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 181 && text <= 199) || text <= 69"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
  const tableColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      dataIndex: 'fasting',
      key: 'fasting',
      slots: {
        title: 'fasting',
        customRender: 'fastingValue',
      },
      align: 'center',
    },
    {
      dataIndex: 'preprandial',
      key: 'preprandial',
      slots: {
        title: 'preprandial',
        customRender: 'fastingValue',
      },
      align: 'center',
    },
    {
      dataIndex: 'postprandial',
      key: 'postprandial',
      slots: {
        title: 'postprandial',
        customRender: 'postprandialValue',
      },
      align: 'center',
    },
  ]
  import { toRef, watch, onMounted, ref } from 'vue'
  import { measurementAvg } from '@/utils/index'
  export default {
    props: {
      records: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'records')
      const data = ref([])
      const tableInit = () => {
        // 期間平均值
        data.value = [
          {
            name: '期間平均值',
            fasting: measurementAvg(
              dataCopy.value.filter(
                (item) => item.measurement.meal == 'Fasting'
              ),
              'bloodGlucose'
            ),
            preprandial: measurementAvg(
              dataCopy.value.filter(
                (item) => item.measurement.meal == 'Before Meal'
              ),
              'bloodGlucose'
            ),
            postprandial: measurementAvg(
              dataCopy.value.filter(
                (item) => item.measurement.meal == 'After Meal'
              ),
              'bloodGlucose'
            ),
          },
          {
            name: '期間最高值',
            fasting: Math.max.apply(
              Math,
              dataCopy.value
                .filter((item) => item.measurement.meal == 'Fasting')
                .map((o) => o.measurement.bloodGlucose)
            ),
            preprandial: Math.max.apply(
              Math,
              dataCopy.value
                .filter((item) => item.measurement.meal == 'Preprandial')
                .map((o) => o.measurement.bloodGlucose)
            ),
            postprandial: Math.max.apply(
              Math,
              dataCopy.value
                .filter((item) => item.measurement.meal == 'Postprandial')
                .map((o) => o.measurement.bloodGlucose)
            ),
          },
          {
            name: '期間最低值',
            fasting: Math.min.apply(
              Math,
              dataCopy.value
                .filter((item) => item.measurement.meal == 'Fasting')
                .map((o) => o.measurement.bloodGlucose)
            ),
            preprandial: Math.min.apply(
              Math,
              dataCopy.value
                .filter((item) => item.measurement.meal == 'Preprandial')
                .map((o) => o.measurement.bloodGlucose)
            ),
            postprandial: Math.min.apply(
              Math,
              dataCopy.value
                .filter((item) => item.measurement.meal == 'Postprandial')
                .map((o) => o.measurement.bloodGlucose)
            ),
          },
        ]
      }

      onMounted(() => {
        tableInit()
      })

      watch(
        () => props.records,
        () => {
          tableInit()
        }
      )

      return {
        data,
        tableColumns,
      }
    },
  }
</script>

<style lang="less" scoped>
  .abnormal {
    color: #8b2331;
  }
  .attention {
    color: #f08719;
  }
</style>
