<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import { getChartYAxisRange, measurementAvg } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      const option = ref(null)
      const echartInit = () => {
        let pulse = [],
          systolic = [],
          diastolic = [],
          pulsePressureDiff = [],
          recordCount = []
        // 上午時段數據
        const measureMorning = dataCopy.value.filter((item) => {
          const hour = new Date(item.measurement.measured).getHours()
          return hour >= 0 && hour < 12
        })
        if (measurementAvg(measureMorning, 'systolic') != Infinity)
          systolic.push(measurementAvg(measureMorning, 'systolic'))
        if (measurementAvg(measureMorning, 'diastolic') != Infinity)
          diastolic.push(measurementAvg(measureMorning, 'diastolic'))
        if (measurementAvg(measureMorning, 'pulse') != Infinity)
          pulse.push(measurementAvg(measureMorning, 'pulse'))
        if (
          measurementAvg(measureMorning, 'pulsePressureDifference') != Infinity
        )
          pulsePressureDiff.push(
            measurementAvg(measureMorning, 'pulsePressureDifference')
          )
        recordCount.push(measureMorning.length)

        // 下午時段數據
        const measureAfternoon = dataCopy.value.filter((item) => {
          const hour = new Date(item.measurement.measured).getHours()
          return hour >= 12 && hour < 18
        })

        if (measurementAvg(measureAfternoon, 'systolic') != Infinity)
          systolic.push(measurementAvg(measureAfternoon, 'systolic'))
        if (measurementAvg(measureAfternoon, 'diastolic') != Infinity)
          diastolic.push(measurementAvg(measureAfternoon, 'diastolic'))
        if (measurementAvg(measureAfternoon, 'pulse') != Infinity)
          pulse.push(measurementAvg(measureAfternoon, 'pulse'))
        if (
          measurementAvg(measureAfternoon, 'pulsePressureDifference') !=
          Infinity
        )
          pulsePressureDiff.push(
            measurementAvg(measureAfternoon, 'pulsePressureDifference')
          )
        recordCount.push(measureAfternoon.length)

        // 晚上時段數據
        const measureNight = dataCopy.value.filter((item) => {
          const hour = new Date(item.measurement.measured).getHours()
          return hour >= 18 && hour < 24
        })
        if (measurementAvg(measureNight, 'systolic') != Infinity)
          systolic.push(measurementAvg(measureNight, 'systolic'))
        if (measurementAvg(measureNight, 'diastolic') != Infinity)
          diastolic.push(measurementAvg(measureNight, 'diastolic'))
        if (measurementAvg(measureNight, 'pulse') != Infinity)
          pulse.push(measurementAvg(measureNight, 'pulse'))
        if (measurementAvg(measureNight, 'pulsePressureDifference') != Infinity)
          pulsePressureDiff.push(
            measurementAvg(measureNight, 'pulsePressureDifference')
          )
        recordCount.push(measureNight.length)

        // 取得血壓Y軸刻度範圍
        const bpDataRange = {
          min: 0,
          max: Math.max.apply(
            Math,
            systolic.filter((item) => item != Infinity)
          ),
        }

        const bpChartRange = getChartYAxisRange(bpDataRange)

        const data = [
          {
            name: '收縮壓',
            type: 'bar',
            data: systolic,
          },
          {
            name: '舒張壓',
            type: 'bar',
            data: diastolic,
          },
          {
            name: '脈壓差',
            type: 'bar',
            data: pulsePressureDiff,
          },
        ]
        option.value = {
          title: {
            text: '時段 - 血壓平均值',
            textStyle: {
              fontSize: '20',
            },
            left: 'center',
          },
          color: ['#56CCF2', '#27AE60', '#FD91B8'],
          grid: {
            left: '30',
            right: '30',
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              let tar = `時段 `
              switch (params[0].name) {
                case '0-12':
                  tar += '上午<br>'
                  break
                case '12-18':
                  tar += '下午<br>'
                  break
                case '18-24':
                  tar += '晚上<br>'
                  break
              }
              tar += `紀錄筆數 ${recordCount[params[0].dataIndex]} 筆<br/>`
              params.forEach((item) => {
                tar += `<span class="chart-tooltip-point" style="background-color:${item.color};"></span>
                平均${item.seriesName}:  ${item.value} 毫米汞柱<br/>`
              })
              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['收縮壓', '舒張壓', '脈壓差'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'category',
              data: ['0-12', '12-18', '18-24'],
              name: '時',

              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '毫米汞柱',
              min: bpChartRange.min,
              max: bpChartRange.max,
              interval: bpChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          series: data,
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style></style>
