import { render } from "./CaseTemp.vue?vue&type=template&id=6acb3ede"
import script from "./CaseTemp.vue?vue&type=script&lang=js"
export * from "./CaseTemp.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6acb3ede"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6acb3ede', script)) {
    api.reload('6acb3ede', script)
  }
  
  module.hot.accept("./CaseTemp.vue?vue&type=template&id=6acb3ede", () => {
    api.rerender('6acb3ede', render)
  })

}

script.__file = "src/components/charts/CaseTemp.vue"

export default script