import { render } from "./BodyRecordDataTable.vue?vue&type=template&id=abe277ae&scoped=true"
import script from "./BodyRecordDataTable.vue?vue&type=script&lang=js"
export * from "./BodyRecordDataTable.vue?vue&type=script&lang=js"

import "./BodyRecordDataTable.vue?vue&type=style&index=0&id=abe277ae&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-abe277ae"
/* hot reload */
if (module.hot) {
  script.__hmrId = "abe277ae"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('abe277ae', script)) {
    api.reload('abe277ae', script)
  }
  
  module.hot.accept("./BodyRecordDataTable.vue?vue&type=template&id=abe277ae&scoped=true", () => {
    api.rerender('abe277ae', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/BodyRecordDataTable.vue"

export default script