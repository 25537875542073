<template>
  <div class="receiveDataTable-component">
    <a-anchor>
      <a-row
        type="flex"
        justify="space-between"
        align="middle"
        id="tableAction"
      >
        <!-- <a-button type="primary">匯出(.xlsx)</a-button> -->
        <div v-if="data.length > 0" style="margin-right: 200px">
          總共筆數：{{ dataLength }}
        </div>
      </a-row>
      <a-table
        :pagination="tableSetting"
        :bordered="true"
        :columns="tableColumns"
        :data-source="data"
        :scroll="{ x: 600, y: windowHeight - 110 }"
        :locale="{ emptyText: '' }"
      >
        <template #action="{ text }">
          <a-list size="small">
            <a-list-item v-if="text.action1 && text.sellStatus == 0">
              <a-popconfirm
                title="確定要作廢此領取？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="action1(text.id)"
              >
                <a href="#">{{ text.action1 }}</a>
              </a-popconfirm>
            </a-list-item>
            <a-list-item v-if="text.action2">
              <a @click="action2(text.id)">
                {{ text.action2 }}
              </a>
            </a-list-item>
            <a-list-item v-if="text.action3">
              <a @click="action3(text.id)">
                {{ text.action3 }}
              </a>
            </a-list-item>
            <a-list-item v-if="text.action4">
              <a @click="action4(text.id)">
                {{ text.action4 }}
              </a>
            </a-list-item>
            <template></template>
          </a-list>
        </template>
      </a-table>
    </a-anchor>
  </div>
</template>

<script>
  import { reactive, computed, ref } from 'vue'
  import { numberComma } from '@/utils/index'
  export default {
    props: ['searchResult', 'columns'],
    setup(props, { emit }) {
      const data = computed(() => props.searchResult)
      const tableColumns = computed(() => props.columns)
      const dataLength = computed(() => numberComma(data.value.length))
      const selectType = ref('basic')
      const windowHeight = window.innerHeight
      const tableSetting = reactive({
        currentPage: 1,
        pageSize: 20,
        position: 'top',
        simple: true,
      })

      const action1 = (value) => {
        emit('action1', value)
      }
      const action2 = (value) => {
        emit('action2', value)
      }
      const action3 = (value) => {
        emit('action3', value)
      }
      const action4 = (value) => {
        emit('action4', value)
      }

      return {
        tableColumns,
        tableSetting,
        data,
        dataLength,
        action1,
        action2,
        action3,
        action4,
        selectType,
        windowHeight,
      }
    },
  }
</script>

<style lang="less">
  .receiveDataTable-component {
    #tableAction {
      .ant-radio-group,
      button {
        z-index: 5;
      }
    }
    .ant-pagination.ant-table-pagination {
      text-align: right;
      margin: -25px 0 0 0;
    }

    .ant-table-thead > tr {
      th {
        text-align: center;
        height: 60px;
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 5px;
    }

    .ant-table-placeholder {
      display: none;
    }
  }
</style>
