<template>
  <a-row :gutter="48">
    <a-col :span="24">
      <CaseBp :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <CaseBpTimeAvg :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <a-tabs class="bp-type" v-model:activeKey="BpTypeKey">
        <a-tab-pane key="systolic" tab="收縮壓"></a-tab-pane>
        <a-tab-pane key="diastolic" tab="舒張壓"></a-tab-pane>
        <a-tab-pane key="pulsePressureDifference" tab="脈壓差"></a-tab-pane>
        <a-tab-pane key="pulse" tab="脈博"></a-tab-pane>
      </a-tabs>
      <CaseBpPercentage :data="measurements" :bpItem="BpTypeKey" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <BpAvgTable :records="measurements" />
    </a-col>
  </a-row>
  <BpRecordDataTable :records="measurements" />
</template>

<script>
  import { ref, toRef } from 'vue'
  import CaseBp from '@/components/charts/CaseBp'
  import CaseBpTimeAvg from '@/components/charts/CaseBpTimeAvg'
  import CaseBpPercentage from '@/components/charts/CaseBpPercentage'
  import BpAvgTable from '@/views/healthManagement/caseManagement/caseDetail/BpAvgTable'
  import BpRecordDataTable from '@/views/healthManagement/caseManagement/caseDetail/BpRecordDataTable'
  export default {
    components: {
      CaseBp,
      CaseBpTimeAvg,
      CaseBpPercentage,
      BpAvgTable,
      BpRecordDataTable,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const measurements = toRef(props, 'data')
      const BpTypeKey = ref('systolic') // 血壓異常比例tab key
      return {
        measurements,
        BpTypeKey,
      }
    },
  }
</script>

<style lang="less" scoped>
  .ant-col {
    height: 400px;
  }
</style>
