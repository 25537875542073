import { render } from "./CaseBpTimeAvg.vue?vue&type=template&id=35574c7d"
import script from "./CaseBpTimeAvg.vue?vue&type=script&lang=js"
export * from "./CaseBpTimeAvg.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "35574c7d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('35574c7d', script)) {
    api.reload('35574c7d', script)
  }
  
  module.hot.accept("./CaseBpTimeAvg.vue?vue&type=template&id=35574c7d", () => {
    api.rerender('35574c7d', render)
  })

}

script.__file = "src/components/charts/CaseBpTimeAvg.vue"

export default script