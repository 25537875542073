import { render } from "./BasicTab.vue?vue&type=template&id=8920df02"
import script from "./BasicTab.vue?vue&type=script&lang=js"
export * from "./BasicTab.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8920df02"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8920df02', script)) {
    api.reload('8920df02', script)
  }
  
  module.hot.accept("./BasicTab.vue?vue&type=template&id=8920df02", () => {
    api.rerender('8920df02', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/BasicTab.vue"

export default script