<template>
  <div ref="container" style="min-height: 400px; margin: 0 -20px"></div>
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import * as echarts from 'echarts/core'
  import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    DataZoomComponent,
  } from 'echarts/components'
  import { LineChart } from 'echarts/charts'
  import { CanvasRenderer } from 'echarts/renderers'
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    DataZoomComponent,
    LineChart,
    CanvasRenderer,
  ])
  import moment from 'moment'
  import { getChartYAxisRange, round } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      let weight,
        bmi,
        bmr,
        visceralFat,
        bodyFat,
        muscleMass = []
      const yChartRange = {}
      let selectLegend = 'BMI'
      const container = ref(null)
      const echartInit = () => {
        // 排序資料
        dataCopy.value.sort(
          (a, b) =>
            new Date(a.measurement.measured) - new Date(b.measurement.measured)
        )

        // 取得體重Y軸刻度範圍
        const weightDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.weight)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.weight)
          ),
        }
        const weightChartRange = getChartYAxisRange(weightDataRange)

        // 取得bmiY軸刻度範圍
        const bmiDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bmi)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bmi)
          ),
        }
        yChartRange['BMI'] = getChartYAxisRange(bmiDataRange)
        yChartRange['BMI'].lableName = 'BMI'

        // 取得bmrY軸刻度範圍
        const bmrDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bmr)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bmr)
          ),
        }
        yChartRange['基礎代謝率'] = getChartYAxisRange(bmrDataRange)
        yChartRange['基礎代謝率'].lableName = '大卡'

        // 取得內臟脂肪Y軸刻度範圍
        const visceralFatDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.visceralFat)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.visceralFat)
          ),
        }
        yChartRange['內臟脂肪'] = getChartYAxisRange(visceralFatDataRange)
        yChartRange['內臟脂肪'].lableName = '等級'

        // 取得體脂率Y軸刻度範圍
        const bodyFatDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bodyFat)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bodyFat)
          ),
        }
        yChartRange['體脂率'] = getChartYAxisRange(bodyFatDataRange)
        yChartRange['體脂率'].lableName = '%'

        // 取得肌肉率Y軸刻度範圍
        const muscleMassDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.muscleMass)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.muscleMass)
          ),
        }
        yChartRange['肌肉率'] = getChartYAxisRange(muscleMassDataRange)
        yChartRange['肌肉率'].lableName = '%'

        // 體重
        weight = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.weight,
        ])

        // BMI
        bmi = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.bmi,
        ])

        // 基礎代謝率
        bmr = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.bmr,
        ])

        // 內臟脂肪
        visceralFat = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.visceralFat,
        ])

        // 體脂率
        bodyFat = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.bodyFat,
        ])

        // 肌肉率
        muscleMass = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.muscleMass,
        ])

        const myChart = echarts.init(container.value)
        const data = [
          {
            name: '體重',
            type: 'bar',
            barWidth: 10,
            data: weight,
          },
          {
            name: 'BMI',
            type: 'line',
            yAxisIndex: 1,
            data: bmi,
          },
          {
            name: '基礎代謝率',
            type: 'line',
            yAxisIndex: 1,
            data: bmr,
          },
          {
            name: '內臟脂肪',
            type: 'line',
            yAxisIndex: 1,
            data: visceralFat,
          },
          {
            name: '體脂率',
            type: 'line',
            yAxisIndex: 1,
            data: bodyFat,
          },
          {
            name: '肌肉率',
            type: 'line',
            yAxisIndex: 1,
            data: muscleMass,
          },
        ]
        const option = {
          title: {
            text: '身體組成紀錄',
            textStyle: {
              fontSize: '20',
            },
            left: '30',
          },
          color: [
            'rgba(189, 189, 189, 1)',
            '#EB5757',
            '#F2994A',
            '#F2C94C',
            '#219653',
            '#2F80ED',
          ],
          grid: {
            left: '60',
            right: '5%',
            bottom: '70',
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              let tar = `量測日期 ${moment(params[0].value[0]).format(
                'YYYY-MM-DD'
              )}<br/>`
              const weightValue = weight.find(
                (item) => item[0] === params[0].value[0]
              )
              tar += `體重 ${round(weightValue[1], 1)} 公斤<br/>`
              const bmiValue = bmi.find(
                (item) => item[0] === params[0].value[0]
              )
              tar += `BMI ${round(bmiValue[1], 1)}<br/>`

              const bmrValue = bmr.find(
                (item) => item[0] === params[0].value[0]
              )
              tar += `基礎代謝率 ${round(bmrValue[1])} 大卡<br/>`

              const visceralFatValue = visceralFat.find(
                (item) => item[0] === params[0].value[0]
              )
              tar += `內臟脂肪 ${visceralFatValue[1]} 等級<br/>`

              const bodyFatValue = bodyFat.find(
                (item) => item[0] === params[0].value[0]
              )
              tar += `體脂率 ${round(bodyFatValue[1], 1)} %<br/>`

              const muscleMassValue = muscleMass.find(
                (item) => item[0] === params[0].value[0]
              )
              tar += `肌肉率 ${round(muscleMassValue[1], 1)} %<br/>`

              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['體重', 'BMI', '基礎代謝率', '內臟脂肪', '體脂率', '肌肉率'],
            left: 'right',
            selected: {
              體重: true,
              BMI: true,
              基礎代謝率: false,
              內臟脂肪: false,
              體脂率: false,
              肌肉率: false,
            },
          },
          xAxis: [
            {
              type: 'time',
              axisPointer: {
                type: 'shadow',
              },
              minInterval: 3600 * 24 * 1000,
              maxInterval: 3600 * 24 * 1000,
              axisLabel: {
                formatter: (value) =>
                  moment(value).format('dd') +
                  '\n' +
                  moment(value).format('M/D'),
              },
              minorTick: {
                show: true,
                splitNumber: 2,
                length: 3,
              },
              splitLine: {
                lineStyle: {
                  color: '#99E1D6',
                  width: 2,
                },
              },
              minorSplitLine: {
                show: true,
                lineStyle: {
                  color: '#BFECE5',
                  type: 'dashed',
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '公斤',
              min: weightChartRange.min,
              max: weightChartRange.max,
              interval: weightChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
            {
              type: 'value',
              name: yChartRange[selectLegend].lableName,
              min: yChartRange[selectLegend].min,
              max: yChartRange[selectLegend].max,
              interval: yChartRange[selectLegend].interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100,
              minValueSpan: 3600 * 24 * 1000 * 7,
              maxValueSpan: 3600 * 24 * 1000 * 30,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: data,
        }
        myChart.setOption(option)
        window.addEventListener('resize', () => {
          myChart.resize()
        })
        myChart.on('legendselectchanged', function (params) {
          selectLegend = params.name
          myChart.setOption({
            ...option,
            yAxis: [
              {
                type: 'value',
                name: '%',
                min: weightChartRange.min,
                max: weightChartRange.max,
                interval: weightChartRange.interval,
                axisLabel: {
                  formatter: '{value}',
                },
              },
              {
                type: 'value',
                name: yChartRange[selectLegend].lableName,
                min: yChartRange[selectLegend].min,
                max: yChartRange[selectLegend].max,
                interval: yChartRange[selectLegend].interval,
                axisLabel: {
                  formatter: '{value}',
                },
              },
            ],
            legend: {
              selected: {
                BMI: false,
                基礎代謝率: false,
                內臟脂肪: false,
                體脂率: false,
                肌肉率: false,
                [params.name]: true,
              },
            },
          })
        })
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        props,
        container,
        echartInit,
      }
    },
  }
</script>

<style></style>
