<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import { getChartYAxisRange, measurementAvg } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      const option = ref(null)
      const echartInit = () => {
        let fasting = [],
          preprandial = [],
          postprandial = [],
          recordCount = {}
        // 上午時段數據
        const measureMorning = dataCopy.value.filter((item) => {
          const hour = new Date(item.measurement.measured).getHours()
          return hour >= 0 && hour < 12
        })
        fasting.push(
          measurementAvg(
            measureMorning.filter((item) => item.measurement.meal == 'Fasting'),
            'bloodGlucose'
          )
        )
        preprandial.push(
          measurementAvg(
            measureMorning.filter(
              (item) => item.measurement.meal == 'Before Meal'
            ),
            'bloodGlucose'
          )
        )
        postprandial.push(
          measurementAvg(
            measureMorning.filter(
              (item) => item.measurement.meal == 'After Meal'
            ),
            'bloodGlucose'
          )
        )
        recordCount['0-12'] = [
          measureMorning.filter((item) => item.measurement.meal == 'Fasting')
            .length,
          measureMorning.filter(
            (item) => item.measurement.meal == 'Before Meal'
          ).length,
          measureMorning.filter((item) => item.measurement.meal == 'After Meal')
            .length,
        ]

        // 下午時段數據
        const measureAfternoon = dataCopy.value.filter((item) => {
          const hour = new Date(item.measurement.measured).getHours()
          return hour >= 12 && hour < 18
        })
        fasting.push(
          measurementAvg(
            measureAfternoon.filter(
              (item) => item.measurement.meal == 'Fasting'
            ),
            'bloodGlucose'
          )
        )
        preprandial.push(
          measurementAvg(
            measureAfternoon.filter(
              (item) => item.measurement.meal == 'Preprandial'
            ),
            'bloodGlucose'
          )
        )
        postprandial.push(
          measurementAvg(
            measureAfternoon.filter(
              (item) => item.measurement.meal == 'Postprandial'
            ),
            'bloodGlucose'
          )
        )
        recordCount['12-18'] = [
          measureAfternoon.filter((item) => item.measurement.meal == 'Fasting')
            .length,
          measureAfternoon.filter(
            (item) => item.measurement.meal == 'Preprandial'
          ).length,
          measureAfternoon.filter(
            (item) => item.measurement.meal == 'Postprandial'
          ).length,
        ]

        // 晚上時段數據
        const measureNight = dataCopy.value.filter((item) => {
          const hour = new Date(item.measurement.measured).getHours()
          return hour >= 18 && hour < 24
        })
        fasting.push(
          measurementAvg(
            measureNight.filter((item) => item.measurement.meal == 'Fasting'),
            'bloodGlucose'
          )
        )
        preprandial.push(
          measurementAvg(
            measureNight.filter(
              (item) => item.measurement.meal == 'Preprandial'
            ),
            'bloodGlucose'
          )
        )
        postprandial.push(
          measurementAvg(
            measureNight.filter(
              (item) => item.measurement.meal == 'Postprandial'
            ),
            'bloodGlucose'
          )
        )
        recordCount['18-24'] = [
          measureNight.filter((item) => item.measurement.meal == 'Fasting')
            .length,
          measureNight.filter((item) => item.measurement.meal == 'Preprandial')
            .length,
          measureNight.filter((item) => item.measurement.meal == 'Postprandial')
            .length,
        ]

        // 取得血壓Y軸刻度範圍
        const glucoseDataRange = {
          min: Math.min.apply(Math, [
            ...fasting,
            ...preprandial,
            ...postprandial,
          ]),
          max: Math.max.apply(Math, [
            ...fasting,
            ...preprandial,
            ...postprandial,
          ]),
        }
        const bpChartRange = getChartYAxisRange(glucoseDataRange)

        const data = [
          {
            name: '空腹',
            type: 'bar',
            data: fasting,
          },
          {
            name: '餐前',
            type: 'bar',
            data: preprandial,
          },
          {
            name: '餐後',
            type: 'bar',
            data: postprandial,
          },
        ]
        option.value = {
          title: {
            text: '區間內時段 - 血糖平均狀態分布',
            textStyle: {
              fontSize: '20',
            },
            left: 'center',
          },
          color: ['#56CCF2', '#27AE60', '#FD91B8'],
          grid: {
            left: '30',
            right: '30',
          },
          tooltip: {
            trigger: 'item',
            formatter: (params) => {
              let tar = `時段 `
              switch (params.name) {
                case '0-12':
                  tar += '上午<br>'
                  break
                case '12-18':
                  tar += '下午<br>'
                  break
                case '18-24':
                  tar += '晚上<br>'
                  break
              }
              tar += `紀錄筆數 ${
                recordCount[params.name][params.seriesIndex]
              } 筆<br/>`
              tar += `<span class="chart-tooltip-point" style="background-color:${params.color};"></span>
                平均${params.seriesName}血糖值:  ${params.value} 毫米汞柱<br/>`
              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['空腹', '餐前', '餐後'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'category',
              data: ['0-12', '12-18', '18-24'],
              name: '時',

              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '毫克/分升',
              min: bpChartRange.min,
              max: bpChartRange.max,
              interval: bpChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          series: data,
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style></style>
