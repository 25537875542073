import { render } from "./CaseGlucoseTimeAvg.vue?vue&type=template&id=c70d97fe"
import script from "./CaseGlucoseTimeAvg.vue?vue&type=script&lang=js"
export * from "./CaseGlucoseTimeAvg.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c70d97fe"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c70d97fe', script)) {
    api.reload('c70d97fe', script)
  }
  
  module.hot.accept("./CaseGlucoseTimeAvg.vue?vue&type=template&id=c70d97fe", () => {
    api.rerender('c70d97fe', render)
  })

}

script.__file = "src/components/charts/CaseGlucoseTimeAvg.vue"

export default script