<template>
  <div class="table-container">
    <div v-if="data.length > 0" id="table-count">
      總共筆數：{{ dataLength }}
    </div>
    <a-table
      :pagination="tableSetting"
      :bordered="true"
      :columns="tableColumns"
      :data-source="data"
      :rowKey="(record) => record.measurement.measured"
      :scroll="{ x: 600, y: windowHeight - 110 }"
      size="small"
    >
      <template #action="{ record }">
        <a @click="action1(record.measurement.id)">
          {{ parseTime(record.measurement.measured) }}
        </a>
      </template>
      <template #systolic>
        <span style="font-size: 16px">收縮壓</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫米汞柱)</span>
      </template>
      <template #systolicValue="{ text }">
        <span v-if="text >= 180" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 30 && text <= 89) || (text >= 140 && text <= 179)"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #diastolic>
        <span style="font-size: 16px">舒張壓</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫米汞柱)</span>
      </template>
      <template #diastolicValue="{ text }">
        <span v-if="text >= 110" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 30 && text <= 59) || (text >= 90 && text <= 109)"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #pulsePressureDiff>
        <span style="font-size: 16px">脈壓差</span>
        <br />
        <span style="font-size: 14px; color: #828282">(毫米汞柱)</span>
      </template>
      <template #pulsePressureDiffValue="{ text }">
        <span v-if="text >= 60 || text <= 20" class="abnormal">
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #pulse>
        <span style="font-size: 16px">脈搏</span>
        <br />
        <span style="font-size: 14px; color: #828282">(次/分鐘)</span>
      </template>
      <template #pulseValue="{ text }">
        <span v-if="text >= 150" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 40 && text <= 59) || (text >= 101 && text <= 149)"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #check="{ text }">
        <CheckOutlined v-if="text" />
      </template>
    </a-table>
  </div>
</template>

<script>
  import { reactive, computed, toRef } from 'vue'
  import { CheckOutlined } from '@ant-design/icons-vue'
  import { numberComma, parseTime } from '@/utils/index'
  export default {
    components: { CheckOutlined },
    props: {
      records: {
        type: Array,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      const data = toRef(props, 'records')
      const windowHeight = window.innerHeight
      const dataLength = computed(() => numberComma(data.value.length))
      const tableSetting = reactive({
        currentPage: 1,
        pageSize: 20,
        position: 'top',
        simple: true,
      })
      const tableColumns = [
        {
          title: '量測日期',
          width: 240,
          dataIndex: 'measurement.measured',
          key: 'date',
          fixed: 'left',
          align: 'center',
          type: 'all',
          slots: { customRender: 'action' },
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            return ('' + a.measurement.measured).localeCompare(
              b.measurement.measured
            )
          },
        },
        {
          key: 'systolic',
          align: 'center',
          slots: {
            title: 'systolic',
            customRender: 'systolicValue',
          },
          dataIndex: 'measurement.systolic',
        },
        {
          key: 'diastolic',
          align: 'center',
          slots: {
            title: 'diastolic',
            customRender: 'diastolicValue',
          },
          dataIndex: 'measurement.diastolic',
        },
        {
          key: 'pulsePressureDiff',
          align: 'center',
          slots: {
            title: 'pulsePressureDiff',
            customRender: 'pulsePressureDiffValue',
          },
          dataIndex: 'measurement.pulsePressureDifference',
        },
        {
          key: 'diastolic',
          align: 'center',
          slots: {
            title: 'pulse',
            customRender: 'pulseValue',
          },
          dataIndex: 'measurement.pulse',
        },
        {
          title: '心律不整',
          key: 'diastolic',
          align: 'center',
          slots: {
            customRender: 'check',
          },
          dataIndex: 'measurement.arr',
        },
        {
          title: '心房顫動',
          key: 'diastolic',
          align: 'center',
          slots: {
            customRender: 'check',
          },
          dataIndex: 'measurement.afib',
        },
        {
          title: '早期收縮',
          key: 'diastolic',
          align: 'center',
          slots: {
            customRender: 'check',
          },
          dataIndex: 'measurement.pc',
        },
      ]

      const action1 = (value) => {
        emit('action1', value)
      }

      return {
        tableColumns,
        tableSetting,
        data,
        dataLength,
        action1,
        windowHeight,
        parseTime,
      }
    },
  }
</script>

<style lang="less" scoped>
  .table-container {
    position: relative;
    #table-count {
      position: absolute;
      right: 200px;
      top: 10px;
    }
  }
  .ant-pagination.ant-table-pagination {
    float: right;
    text-align: right;
    margin: -25px 0 0 0;
  }

  .ant-table-thead > tr {
    th {
      text-align: center;
      height: 60px;
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px;
  }
  .abnormal {
    color: #8b2331;
  }
  .attention {
    color: #f08719;
  }
</style>
