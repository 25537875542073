<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import moment from 'moment'
  import { getChartYAxisRange } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      let // pulse,
        blank,
        bpNormal,
        bpUnNormal = []
      const option = ref(null)
      const echartInit = () => {
        // 排序資料
        dataCopy.value.sort(
          (a, b) =>
            new Date(a.measurement.measured) - new Date(b.measurement.measured)
        )

        // 取得脈搏Y軸刻度範圍
        const pulseDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.pulseLowest)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.pulseLowest)
          ),
        }
        const pulseChartRange = getChartYAxisRange(pulseDataRange)

        // 取得血氧Y軸刻度範圍
        const bpDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.spo2Lowest)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.spo2Highest)
          ),
        }
        const bpChartRange = getChartYAxisRange(bpDataRange)

        // 脈搏
        // pulse = dataCopy.value.map((item) => [
        //   item.measurement.measured,
        //   item.measurement.pulseLowest,
        // ])
        // 空白(為了堆疊數值用)
        blank = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.spo2Lowest,
        ])
        // 正常數值
        bpNormal = dataCopy.value.map((item) => [
          item.measurement.measured,
          item.measurement.isNormal
            ? item.measurement.spo2Highest - item.measurement.spo2Lowest
            : '-',
        ])
        // 異常數值
        bpUnNormal = dataCopy.value.map((item) => [
          item.measurement.measured,
          !item.measurement.isNormal
            ? item.measurement.spo2Highest - item.measurement.spo2Lowest
            : '-',
        ])

        const data = [
          {
            name: '辅助',
            type: 'bar',
            stack: '血氧',
            itemStyle: {
              barBorderColor: 'rgba(0,0,0,0)',
              color: 'rgba(0,0,0,0)',
            },
            emphasis: {
              itemStyle: {
                barBorderColor: 'rgba(0,0,0,0)',
                color: 'rgba(0,0,0,0)',
              },
            },
            data: blank,
          },
          {
            name: '異常記錄',
            type: 'bar',
            barWidth: 10,
            stack: '血氧',
            data: bpUnNormal,
          },
          {
            name: '正常記錄',
            type: 'bar',
            barWidth: 10,
            stack: '血氧',
            data: bpNormal,
          },
        ]
        option.value = {
          title: {
            text: '血氧紀錄',
            textStyle: {
              fontSize: '20',
            },
            left: '30',
          },
          color: [
            'rgba(189, 189, 189, 1)',
            '#EB5757',
            '#F2994A',
            '#F2C94C',
            '#219653',
            '#2F80ED',
          ],
          grid: {
            left: '60',
            right: '5%',
            bottom: '70',
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              let tar = `量測日期 ${moment(params[0].value[0]).format(
                'YYYY-MM-DD'
              )}<br/>`
              if (params[1].value[1] != '-') {
                tar += `<span class="chart-tooltip-point" style="background-color:${
                  params[1].color
                };"></span>
                血氧:  ${params[0].value[1]} - ${
                  params[0].value[1] + params[1].value[1]
                } %<br/>`
              } else {
                tar += `<span class="chart-tooltip-point" style="background-color:${
                  params[2].color
                };"></span>
                血氧:  ${params[0].value[1]} - ${
                  params[0].value[1] + params[2].value[1]
                } %<br/>`
              }
              tar += `<span class="chart-tooltip-point" style="background-color:${params[3].color};"></span>
              ${params[3].seriesName}:  ${params[3].value[1]} 次/分鐘<br/>`
              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['異常記錄', '正常記錄'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'time',
              axisPointer: {
                type: 'shadow',
              },
              minInterval: 3600 * 24 * 1000,
              maxInterval: 3600 * 24 * 1000,
              axisLabel: {
                formatter: (value) =>
                  moment(value).format('dd') +
                  '\n' +
                  moment(value).format('M/D'),
              },
              minorTick: {
                show: true,
                splitNumber: 2,
                length: 3,
              },
              splitLine: {
                lineStyle: {
                  color: '#99E1D6',
                  width: 2,
                },
              },
              minorSplitLine: {
                show: true,
                lineStyle: {
                  color: '#BFECE5',
                  type: 'dashed',
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '%',
              min: bpChartRange.min,
              max: bpChartRange.max,
              interval: bpChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
            {
              type: 'value',
              name: '次/分鐘',
              min: pulseChartRange.min,
              max: pulseChartRange.max,
              interval: pulseChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100,
              minValueSpan: 3600 * 24 * 1000 * 7,
              maxValueSpan: 3600 * 24 * 1000 * 30,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: data,
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style></style>
