<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import moment from 'moment'
  import { getChartYAxisRange } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      let fasting,
        preprandial,
        postprandial = []
      const option = ref(null)
      const echartInit = () => {
        // 排序資料
        dataCopy.value.sort(
          (a, b) =>
            new Date(a.measurement.measured) - new Date(b.measurement.measured)
        )

        // 取得血糖Y軸刻度範圍
        const bpDataRange = {
          min: Math.min.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bloodGlucose)
          ),
          max: Math.max.apply(
            Math,
            dataCopy.value.map((o) => o.measurement.bloodGlucose)
          ),
        }
        const glocoseChartRange = getChartYAxisRange(bpDataRange)

        // 空腹
        fasting = dataCopy.value
          .filter((item) => item.measurement.meal == 'Fasting')
          .map((item) => [
            item.measurement.measured,
            item.measurement.bloodGlucose,
          ])

        // 餐前
        preprandial = dataCopy.value
          .filter((item) => item.measurement.meal == 'Before Meal')
          .map((item) => [
            item.measurement.measured,
            item.measurement.bloodGlucose,
          ])

        // 餐後
        postprandial = dataCopy.value
          .filter((item) => item.measurement.meal == 'After Meal')
          .map((item) => [
            item.measurement.measured,
            item.measurement.bloodGlucose,
          ])

        const data = [
          {
            name: '空腹',
            type: 'line',
            data: fasting,
          },
          {
            name: '餐前',
            type: 'line',
            data: preprandial,
          },
          {
            name: '餐後',
            type: 'line',
            data: postprandial,
          },
        ]
        option.value = {
          title: {
            text: '血糖紀錄',
            textStyle: {
              fontSize: '20',
            },
            left: '30',
          },
          color: ['#FFE600', '#97A014', '#FF5B27'],
          grid: {
            left: '60',
            right: '5%',
            bottom: '70',
          },
          tooltip: {
            trigger: 'item',
            formatter: (params) => {
              let tar = `量測日期 ${moment(params.value[0]).format(
                'YYYY-MM-DD'
              )}<br/>`
              tar += `<span class="chart-tooltip-point" style="background-color:${params.color};"></span>
              用餐狀態:  ${params.seriesName}
              <br/>血糖:  ${params.value[1]} 毫克/分升`
              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['空腹', '餐前', '餐後'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'time',
              axisPointer: {
                type: 'shadow',
              },
              minInterval: 3600 * 24 * 1000,
              maxInterval: 3600 * 24 * 1000,
              axisLabel: {
                formatter: (value) =>
                  moment(value).format('dd') +
                  '\n' +
                  moment(value).format('M/D'),
              },
              minorTick: {
                show: true,
                splitNumber: 2,
                length: 3,
              },
              splitLine: {
                lineStyle: {
                  color: '#99E1D6',
                  width: 2,
                },
              },
              minorSplitLine: {
                show: true,
                lineStyle: {
                  color: '#BFECE5',
                  type: 'dashed',
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '毫米汞柱',
              min: glocoseChartRange.min,
              max: glocoseChartRange.max,
              interval: glocoseChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
            },
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100,
              minValueSpan: 3600 * 24 * 1000 * 7,
              maxValueSpan: 3600 * 24 * 1000 * 30,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: data,
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style></style>
