<template>
  <a-row :gutter="48">
    <a-col :span="24">
      <CaseOs :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <CaseOsTimesCount :data="measurements" />
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <CaseOsPercentage :data="measurements" />
    </a-col>
  </a-row>
  <OsRecordDataTable :records="measurements" />
</template>

<script>
  import { toRef } from 'vue'
  import CaseOs from '@/components/charts/CaseOs'
  import CaseOsTimesCount from '@/components/charts/CaseOsTimesCount'
  import CaseOsPercentage from '@/components/charts/CaseOsPercentage'
  import OsRecordDataTable from '@/views/healthManagement/caseManagement/caseDetail/OsRecordDataTable'
  export default {
    components: {
      CaseOs,
      CaseOsTimesCount,
      CaseOsPercentage,
      OsRecordDataTable,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const measurements = toRef(props, 'data')
      return {
        measurements,
      }
    },
  }
</script>

<style lang="less" scoped>
  .ant-col {
    height: 400px;
  }
</style>
