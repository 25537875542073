<template>
  <div>
    <a-table
      :bordered="true"
      :columns="tableColumns"
      :data-source="data"
      rowKey="name"
      :pagination="false"
    >
      <template #weight>
        <span style="font-size: 16px">體重</span>
        <br />
        <span style="font-size: 14px; color: #828282">(公斤)</span>
      </template>

      <template #bmiValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 30" class="abnormal">{{ text }}</span>
        <span
          v-else-if="(text >= 25 && text <= 29.9) || text < 18.5"
          class="attention"
        >
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #bmr>
        <span style="font-size: 16px">基礎代謝率</span>
        <br />
        <span style="font-size: 14px; color: #828282">(大卡)</span>
      </template>

      <template #visceralFat>
        <span style="font-size: 16px">內臟脂肪</span>
        <br />
        <span style="font-size: 14px; color: #828282">(等級)</span>
      </template>
      <template #visceralFatValue="{ text }">
        <span v-if="!isFinite(text)">無量測資料</span>
        <span v-else-if="text >= 15 && text <= 30" class="abnormal">
          {{ text }}
        </span>
        <span v-else-if="text >= 10 && text <= 14.5" class="attention">
          {{ text }}
        </span>
        <span v-else>{{ text }}</span>
      </template>

      <template #bodyFat>
        <span style="font-size: 16px">體脂率</span>
        <br />
        <span style="font-size: 14px; color: #828282">(%)</span>
      </template>

      <template #muscleMass>
        <span style="font-size: 16px">肌肉率</span>
        <br />
        <span style="font-size: 14px; color: #828282">(%)</span>
      </template>
    </a-table>
  </div>
</template>

<script>
  const tableColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      dataIndex: 'weight',
      key: 'weight',
      slots: {
        title: 'weight',
      },
      align: 'center',
      customRender: ({ text }) => (!isFinite(text) ? '無量測資料' : text),
    },
    {
      dataIndex: 'bmi',
      key: 'bmi',
      title: 'BMI',
      slots: {
        customRender: 'bmiValue',
      },
      align: 'center',
    },
    {
      dataIndex: 'bmr',
      key: 'bmr',
      slots: {
        title: 'bmr',
      },
      align: 'center',
      customRender: ({ text }) => (!isFinite(text) ? '無量測資料' : text),
    },
    {
      dataIndex: 'visceralFat',
      key: 'visceralFat',
      slots: {
        title: 'visceralFat',
        customRender: 'visceralFatValue',
      },
      align: 'center',
    },
    {
      dataIndex: 'bodyFat',
      key: 'bodyFat',
      slots: {
        title: 'bodyFat',
      },
      align: 'center',
      customRender: ({ text }) => (!isFinite(text) ? '無量測資料' : text),
    },
    {
      dataIndex: 'muscleMass',
      key: 'muscleMass',
      slots: {
        title: 'muscleMass',
      },
      align: 'center',
      customRender: ({ text }) => (!isFinite(text) ? '無量測資料' : text),
    },
  ]
  import { toRef, watch, onMounted, ref } from 'vue'
  import { measurementAvg, round } from '@/utils/index'
  export default {
    props: {
      records: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'records')
      const data = ref([])
      const tableInit = () => {
        // 期間平均值
        data.value = [
          {
            name: '期間平均值',
            weight: measurementAvg(dataCopy.value, 'weight', 1),
            bmi: measurementAvg(dataCopy.value, 'bmi', 1),
            bmr: measurementAvg(dataCopy.value, 'bmr'),
            visceralFat: measurementAvg(dataCopy.value, 'visceralFat'),
            bodyFat: measurementAvg(dataCopy.value, 'bodyFat', 1),
            muscleMass: measurementAvg(dataCopy.value, 'bodyFat', 1),
          },
          {
            name: '期間最高值',
            weight: round(
              Math.max.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.weight)
              ),
              1
            ),
            bmi: round(
              Math.max.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bmi)
              ),
              1
            ),
            bmr: round(
              Math.max.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bmr)
              )
            ),
            visceralFat: Math.max.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.visceralFat)
            ),
            bodyFat: round(
              Math.max.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bodyFat)
              ),
              1
            ),
            muscleMass: round(
              Math.max.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.muscleMass)
              ),
              1
            ),
          },
          {
            name: '期間最低值',
            weight: round(
              Math.min.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.weight)
              ),
              1
            ),
            bmi: round(
              Math.min.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bmi)
              ),
              1
            ),
            bmr: round(
              Math.min.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bmr)
              )
            ),
            visceralFat: Math.min.apply(
              Math,
              dataCopy.value.map((o) => o.measurement.visceralFat)
            ),
            bodyFat: round(
              Math.min.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.bodyFat)
              ),
              1
            ),
            muscleMass: round(
              Math.min.apply(
                Math,
                dataCopy.value.map((o) => o.measurement.muscleMass)
              ),
              1
            ),
          },
        ]
      }

      onMounted(() => {
        tableInit()
      })

      watch(
        () => props.records,
        () => {
          tableInit()
        }
      )

      return {
        data,
        tableColumns,
      }
    },
  }
</script>

<style lang="less" scoped>
  .abnormal {
    color: #8b2331;
  }
  .attention {
    color: #f08719;
  }
</style>
