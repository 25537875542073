import { render } from "./CaseBody.vue?vue&type=template&id=1f179dac"
import script from "./CaseBody.vue?vue&type=script&lang=js"
export * from "./CaseBody.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1f179dac"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1f179dac', script)) {
    api.reload('1f179dac', script)
  }
  
  module.hot.accept("./CaseBody.vue?vue&type=template&id=1f179dac", () => {
    api.rerender('1f179dac', render)
  })

}

script.__file = "src/components/charts/CaseBody.vue"

export default script