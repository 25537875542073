import { render } from "./CaseBpPercentage.vue?vue&type=template&id=f9b47edc"
import script from "./CaseBpPercentage.vue?vue&type=script&lang=js"
export * from "./CaseBpPercentage.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f9b47edc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f9b47edc', script)) {
    api.reload('f9b47edc', script)
  }
  
  module.hot.accept("./CaseBpPercentage.vue?vue&type=template&id=f9b47edc", () => {
    api.rerender('f9b47edc', render)
  })

}

script.__file = "src/components/charts/CaseBpPercentage.vue"

export default script