import { render } from "./CaseBp.vue?vue&type=template&id=269e2ed8"
import script from "./CaseBp.vue?vue&type=script&lang=js"
export * from "./CaseBp.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "269e2ed8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('269e2ed8', script)) {
    api.reload('269e2ed8', script)
  }
  
  module.hot.accept("./CaseBp.vue?vue&type=template&id=269e2ed8", () => {
    api.rerender('269e2ed8', render)
  })

}

script.__file = "src/components/charts/CaseBp.vue"

export default script