import { render } from "./GlucoseRecordDataTable.vue?vue&type=template&id=2529381d&scoped=true"
import script from "./GlucoseRecordDataTable.vue?vue&type=script&lang=js"
export * from "./GlucoseRecordDataTable.vue?vue&type=script&lang=js"

import "./GlucoseRecordDataTable.vue?vue&type=style&index=0&id=2529381d&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-2529381d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2529381d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2529381d', script)) {
    api.reload('2529381d', script)
  }
  
  module.hot.accept("./GlucoseRecordDataTable.vue?vue&type=template&id=2529381d&scoped=true", () => {
    api.rerender('2529381d', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/GlucoseRecordDataTable.vue"

export default script