import { render } from "./DataTable.vue?vue&type=template&id=616b85fc"
import script from "./DataTable.vue?vue&type=script&lang=js"
export * from "./DataTable.vue?vue&type=script&lang=js"

import "./DataTable.vue?vue&type=style&index=0&id=616b85fc&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "616b85fc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('616b85fc', script)) {
    api.reload('616b85fc', script)
  }
  
  module.hot.accept("./DataTable.vue?vue&type=template&id=616b85fc", () => {
    api.rerender('616b85fc', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/DataTable.vue"

export default script