import { render } from "./TempAvgTable.vue?vue&type=template&id=84d8a0f0&scoped=true"
import script from "./TempAvgTable.vue?vue&type=script&lang=js"
export * from "./TempAvgTable.vue?vue&type=script&lang=js"

import "./TempAvgTable.vue?vue&type=style&index=0&id=84d8a0f0&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-84d8a0f0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "84d8a0f0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('84d8a0f0', script)) {
    api.reload('84d8a0f0', script)
  }
  
  module.hot.accept("./TempAvgTable.vue?vue&type=template&id=84d8a0f0&scoped=true", () => {
    api.rerender('84d8a0f0', render)
  })

}

script.__file = "src/views/healthManagement/caseManagement/caseDetail/TempAvgTable.vue"

export default script